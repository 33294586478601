<template>
  <div class="church-ministry template-1">
    <page-header class="mx-lg">
      <h1 class="text-uppercase">{{ translations.tcCountryHome }}</h1>
    </page-header>
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components" />
    <!-- / .cards -->
    <div class="page-body container mx-med">
      <section class="section-1">
        <h4 class="text-uppercase">{{ translations.tcUpcomingCountryEvent }}</h4>
        <div class="row">
          <div class="col col-6">
            <b-skeleton-wrapper :loading="loading.event">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <DateCard v-for="(visit, index) in visits" :key="index" :obj="visit.date" :i18n="translations.common">
                <BasicDateCard v-if="translations.components" :i18n="translations.components" :obj="{
                  title: visit.title,
                  description: visit.description,
                  schedule: visit.schedule,
                  destination: visit.destination,
                  meetingDetails: visit.meetingDetails
                }" />
              </DateCard>
              <div class="mt-3">
                <b-button to="/calendar/country/event/state-event-view" variant="primary" class="more">
                  {{ translations.tcViewMore }}
                </b-button>
              </div>
            </b-skeleton-wrapper>
          </div>
          <div class="col col-6">
            <b-skeleton-wrapper :loading="loading.event">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
              <NumberCard v-for="(stat, index) in topWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </b-skeleton-wrapper>
          </div>
        </div>
      </section>
      <section class="announcements">
        <div class="container">
          <div class="row">
            <div class="card col">
              <h4>{{ translations.tcAnnouncements }}</h4>
              <div class="ann-text">{{ announcementsall.msg_message }}</div>
              <div class="row btn-row">
                <b-button to="/get-connected/stay-informed/announcements" variant="secondary">
                  {{ translations.tcSeeMore }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="page-body container mx-med pt-3">
      <section class="section-3">
        <div class="row">
          <InfoCard v-for="(info, index) in countryLandingPageInfo" :key="index" :obj="info" />
        </div>
      </section>
    </div>
    <!-- / .stats -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapState, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import calendarService from '../../../services/calendarService'
import CampSelect from '@/components/CampSelect.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import Event from '@/components/Event.vue'
import Graph from '@/components/Graph.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import moment from 'moment'
import NumberCard from '@/components/number-card/NumberCard.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { calendarMixin } from '@/mixins/calendarMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'country-landing-page',
  mixins: [calendarMixin, translationMixin],
  data() {
    return {
      yearataglance: null,
      stats: [],
      visits: [],
      loading: {
        event: false,
        stat: false
      },
      dataincircle: [],
      topWidgetData: [
        {
          num: 0,
          title: 'PERCENTAGE OF CHURCHES VISITED PAST 12 MONTHS',
          subtitle: ''
        }
      ],
      translations: {},
      countryLandingPageInfo: [
        {
          class: 'col col-6',
          title: 'Country Prayer Calendar',
          content: 'View a list of prayer requests for your country.',
          link: '/programs/pw/prayer-center-daily',
          label: 'View More',
          img: { src: 'svgs/hands-praying.svg', alt: 'Hand Praying', width: 47, height: 64 }
        },
        {
          class: 'col col-6',
          title: 'MEMBER LIST',
          content: 'View a list of all members in your country.',
          link: '/officers/country/directory/members-list',
          label: 'View More',
          img: { src: 'svgs/member-icon.svg', alt: 'Hand Praying', width: 47, height: 64 }
        },
        {
          class: 'col col-6',
          title: 'OTHER COUNTRY LISTS',
          content: 'View a list of camps, speakers, officers, etc. in your country.',
          link: '/officers/state/country-directory',
          label: 'View More',
          img: { src: 'svgs/church-list.svg', alt: 'Hand Praying', width: 47, height: 64 }
        },
        {
          class: 'col col-6',
          title: 'SECURE REPORTS',
          content: 'Run receipt, placement, member and other reports for your country.',
          link: '/reports/report-center',
          label: 'View More',
          img: { src: 'svgs/church-reports.svg', alt: 'Hand Praying', width: 47, height: 64 }
        },
        {
          class: 'col col-6',
          title: 'OFFICER RESOURCES',
          content: 'View manuals, videos, and other resources for country officers.',
          link: '/resources/media-portal',
          label: 'View More',
          img: { src: 'svgs/program-resources.svg', alt: 'Hand Praying', width: 47, height: 64 }
        },
        {
          class: 'col col-6',
          title: '909 CAMP ASSIGNMENTS',
          content: 'View/manage camp visitation assignments.',
          link: '/officers/country/camp-visitation',
          label: 'View More',
          img: { src: 'svgs/visit-icon.svg', alt: 'Hand Praying', width: 47, height: 64 }
        },
        {
          class: 'col col-6',
          title: 'National Association Goal Planner',
          content: 'Plan annual ministry goals for National Associations. ',
          link: '/national-association-goal-planning',
          label: 'View More',
          img: { src: 'svgs/visit-icon.svg', alt: 'Hand Praying', width: 47, height: 64 }
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      getAnnouncementMessages: 'announcements/getAnnouncementForState',
      getChurchVisitedCountry: 'officersModule/getChurchVisitedCountry',
      loadCampDashboardData: 'officersModule/getYearatglance',
      retrieveVideoVisitDetails: 'card/retrieveVideoVisitDetails',
      setSelectedMeeting: 'eventCalendar/setSelectedMeeting',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey'
    }),
    async page_load() {
      this.loading.event = true
      this.loading.stat = true
      await this.getAnnouncementMessages(
        !this.officerToolbarSelected ? this.userStateKey : this.officerToolbarSelected.country_state
      )
      await this.loadMeetings()
        .then(() => {
          this.loading.event = false
        })
        .catch(err => {
          this.loading.event = false
        })
      let church_visited = await this.getChurchVisitedCountry()
      if (church_visited.count !== null) {
        let visited = Math.round((church_visited.count[0].subtotal / church_visited.count[0].total) * 100)
        this.topWidgetData = [
          {
            num: visited > 0 ? visited + '%' : 0,
            title:
              this.translations.tcPercentChurchesVisited12Months || 'PERCENTAGE OF CHURCHES VISITED PAST 12 MONTHS',
            subtitle: ''
          }
        ]
      }
    },
    async loadMeetings() {
      let meetings = await calendarService.getStateMeetings(this.officerSelectStatekey.country_state, null, null)
      this.visits = meetings.data.meetings.slice(0, 3).map(item => {
        let check = moment(item.mtg_start_date, 'YYYY/MM/DD')
        return {
          title: item.mtg_title,
          description: item.vmrt_trn_txt,
          schedule: check.format('dddd'),
          visit_key: item.mtg_key,
          destination: '/calendar/state/event/event-detail',
          date: { month: check.format('MMM'), day: check.format('D'), year: check.format('YYYY') },
          meetingDetails: this.meetingDetails(item)
        }
      })
    },
    updateCountryLandingPageInfo() {
      if (!this.translations.components) return false
      const {
        tc909CampAssignments,
        tc909CampAssignmentsDesc,
        tcCountryPrayerCalendar,
        tcCountryPrayerCalendarDesc,
        tcMemberList,
        tcMemberListDesc,
        tcOfficerResources,
        tcOfficerResourcesDesc,
        tcOtherCountryLists,
        tcOtherCountryListsDesc,
        tcSecureReports,
        tcSecureReportsDesc,
        tcViewMore
      } = this.translations

      this.countryLandingPageInfo[0].content = tcCountryPrayerCalendarDesc
      this.countryLandingPageInfo[0].label = tcViewMore
      this.countryLandingPageInfo[0].title = tcCountryPrayerCalendar

      this.countryLandingPageInfo[1].content = tcMemberListDesc
      this.countryLandingPageInfo[1].label = tcViewMore
      this.countryLandingPageInfo[1].title = tcMemberList

      this.countryLandingPageInfo[2].content = tcOtherCountryListsDesc
      this.countryLandingPageInfo[2].label = tcViewMore
      this.countryLandingPageInfo[2].title = tcOtherCountryLists

      this.countryLandingPageInfo[3].content = tcSecureReportsDesc
      this.countryLandingPageInfo[3].label = tcViewMore
      this.countryLandingPageInfo[3].title = tcSecureReports

      this.countryLandingPageInfo[4].content = tcOfficerResourcesDesc
      this.countryLandingPageInfo[4].label = tcViewMore
      this.countryLandingPageInfo[4].title = tcOfficerResources

      this.countryLandingPageInfo[5].content = tc909CampAssignmentsDesc
      this.countryLandingPageInfo[5].label = tcViewMore
      this.countryLandingPageInfo[5].title = tc909CampAssignments
      //TODO: Add for National Association Goal Planning once they are defined and added on sitecore
      // this.countryLandingPageInfo[5].content = tc909CampAssignmentsDesc
      // this.countryLandingPageInfo[5].label = tcViewMore
      // this.countryLandingPageInfo[5].title = tc909CampAssignments

      return true
    }
  },
  computed: {
    ...mapGetters({
      //announcements: 'announcements/countryAnnouncements',
      announcements: 'announcements/getAnnouncementForState',
      officerSelectStatekey: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCountryObject: 'user/userCountryObject',
      userStateKey: 'user/userStateKey'
    }),
    ...mapState(['user']),
    announcementsall() {
      return !!this.announcements && !!this.announcements.msg_message
        ? this.announcements
        : { msg_message: this.translations.tcNoAnnouncements || 'No Announcements' }
    }
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card'),
      this.getComponentTranslations('common.days', 'common.months-abbreviations')
    ]).then(results => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common }
      }
      this.$set(this.translations, 'components', translatedText)

      const { tcPercentChurchesVisited12Months } = this.translations
      this.topWidgetData[0].title = tcPercentChurchesVisited12Months || 'Percentage of Churches visited past 12 months'

      this.updateCountryLandingPageInfo()
    })
  },
  components: {
    appEvent: Event,
    appGraph: Graph,
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';

.statelanding {
  text-align: center;
}

.church-ministry {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .btn {
    padding: 12px 35px;
    border: none;
    border-radius: 0;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 22px;
    text-transform: uppercase;
  }

  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-secondary {
    background-color: #fbc301;
  }

  .btn.btn-primary {
    background-color: #003946;
  }

  .ann-text {
    color: #747474;
    font-family: 'Lora', serif;
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 36px;
    margin-bottom: 42px;
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .more {
    width: auto !important;
    margin: 30px;
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
