export const GoalsConstants = {
  Customize: 'customize',
  SpreadEqually: 'equal'
}

export const ALL = 'All'
export const ALL_RECEIPTS = 'All Receipts'

export const MedicalTestaments = [
  'Hospital',
  'Soft Cover Bible',
  'Gideon Facilities',
  'Medical',
  'Auxiliary Facilities'
]

export const OtherTestaments = [
  'College',
  'Youth',
  'Sidewalk Youth',
  'Emergency Service'
]
