/* eslint-disable*/
import DashboardService from '@/services/goals/Dashboard.js'
import ChurchMinistryAndChurchPresentationService from '@/services/goals/ChurchMinistryAndPresentation.js'
import GideonCardBibleService from '@/services/goals/GideonCardBible.js'
import AuxiliaryScriptureService from '@/services/goals/AuxiliaryScripture.js'
import CustomizeMonthlyAllocationService from '@/services/goals/CustomizeMonthlyAllocation.js'
import StepperService from '@/services/goals/Stepper.js'
import CampDesignatedService from '@/services/goals/CampDesignated.js'
import PlacementsService from '@/services/goals/Placements.js'
import PersonalWorkersTestamentsService from '@/services/goals/PersonalWorkersTestaments.js'
import FaithFundService from '@/services/goals/FaithFund.js'
import MembershipService from '@/services/goals/Membership.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes.js'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
import { YearRange } from '@/services/utils/YearRange.js'
import { GoalHelpInfo } from '../constants/GoalHelpInfo'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { StepperOrderNumber } from '@/constants/StepperOrderNumber.js'

// CAMP GOALS
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA'
export const SET_CHURCH_MINISTRY_AND_CHURCH_PRESENTATION = 'SET_CHURCH_MINISTRY_AND_CHURCH_PRESENTATION'
export const SET_GIDEON_CARD_DATA = 'SET_GIDEON_CARD_DATA'
export const SET_FAITH_FUND_DATA = 'SET_FAITH_FUND_DATA'
export const SET_BARNABAS_FUND_DATA = 'SET_BARNABAS_FUND_DATA'
export const SET_AUXILIARY_SCRIPTURE_DATA = 'SET_AUXILIARY_SCRIPTURE_DATA'
export const SET_CAMP_DESIGNATED_DATA = 'SET_CAMP_DESIGNATED_DATA'
export const SET_PLACEMENTS_DATA = 'SET_PLACEMENTS_DATA'
export const SET_PERSONAL_WORKERS_TESTAMENTS_DATA = 'SET_PERSONAL_WORKERS_TESTAMENTS_DATA'
export const SET_MEMBERSHIP_DATA = 'SET_MEMBERSHIP_DATA'
export const RESET_PLACEMENTS_MONTHLY_ALLOCATION_DATA = 'RESET_PLACEMENTS_MONTHLY_ALLOCATION_DATA'
export const SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA_AFTER_SAVED = 'SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA_AFTER_SAVED'
export const SET_PWTs_MONTHLY_ALLOCATION_DATA_AFTER_SAVED = 'SET_PWTs_MONTHLY_ALLOCATION_DATA_AFTER_SAVED'
export const RESET_PWTs_MONTHLY_ALLOCATION_DATA = 'RESET_PWTs_MONTHLY_ALLOCATION_DATA'
export const SET_PWTs_MONTHLY_ALLOCATION_DATA = 'SET_PWTs_MONTHLY_ALLOCATION_DATA'
// GOALS STEPPER
export const MARK_GOAL_STEPPER_COMPLETE = 'MARK_GOAL_STEPPER_COMPLETE'
// CUSTOMIZE MONTHLY ALLOCATION
export const SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA = 'SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA'
export const SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA = 'SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA'
// PLANNING FISCAL YEAR
export const SET_SELECTED_FISCAL_YEAR = 'SET_SELECTED_FISCAL_YEAR'
// CAMP GOAL PLANNING WINDOW FLAG
export const SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN = 'SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN'

export const state = () => ({
  isCampGoalPlanningWindowOpen: null,
  selectedFiscalYear: null,
  isDashboardPageLoading: true,
  campGoalsProgressStatus: [],
  dashboardError: null,
  lockedGoalsAccessError: null,
  customizeMonthlyAllocationGoalsData: {},
  dashboard: {},
  churchMinistryAndChurchPresentation: {},
  gideonCard: {},
  faithFund: {},
  auxiliaryScripture: {},
  campDesignated: {},
  placements: {},
  personalWorkersTestaments: {},
  membership: {},
  placementsMonthlyAllocationData: {},
  personalWorkersTestamentsMonthlyAllocationData: {}
})

export const mutations = {
  [SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN](state, isOpenFlag) {
    state.isCampGoalPlanningWindowOpen = isOpenFlag
  },
  [SET_PWTs_MONTHLY_ALLOCATION_DATA_AFTER_SAVED](state, { shortCode, toSaveCustomizedMAData }) {
    state.personalWorkersTestamentsMonthlyAllocationData[shortCode] = toSaveCustomizedMAData
  },
  [RESET_PWTs_MONTHLY_ALLOCATION_DATA](state) {
    state.personalWorkersTestamentsMonthlyAllocationData = {}
  },
  [SET_PWTs_MONTHLY_ALLOCATION_DATA](state, { shortCode, toSaveData }) {
    state.personalWorkersTestamentsMonthlyAllocationData[shortCode] = toSaveData
  },
  [SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA_AFTER_SAVED](state, { shortCode, toSaveCustomizedMAData }) {
    state.placementsMonthlyAllocationData[shortCode] = toSaveCustomizedMAData
  },
  [RESET_PLACEMENTS_MONTHLY_ALLOCATION_DATA](state) {
    state.placementsMonthlyAllocationData = {}
  },
  [SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA](state, { shortCode, toSaveData }) {
    state.placementsMonthlyAllocationData[shortCode] = toSaveData
  },
  [SET_SELECTED_FISCAL_YEAR](state, payload) {
    state.selectedFiscalYear = payload
  },
  [SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA](state, payload) {
    state.customizeMonthlyAllocationGoalsData = payload
  },
  [SET_DASHBOARD_DATA](state, payload) {
    state.dashboard = payload
  },
  [SET_CHURCH_MINISTRY_AND_CHURCH_PRESENTATION](state, payload) {
    state.churchMinistryAndChurchPresentation = payload
  },
  [SET_GIDEON_CARD_DATA](state, payload) {
    state.gideonCard = payload
  },
  [SET_FAITH_FUND_DATA](state, payload) {
    state.faithFund = payload
  },
  [SET_AUXILIARY_SCRIPTURE_DATA](state, payload) {
    state.auxiliaryScripture = payload
  },
  [SET_CAMP_DESIGNATED_DATA](state, payload) {
    state.campDesignated = payload
  },
  [SET_PLACEMENTS_DATA](state, payload) {
    state.placements = payload
  },
  [SET_PERSONAL_WORKERS_TESTAMENTS_DATA](state, payload) {
    state.personalWorkersTestaments = payload
  },
  [SET_MEMBERSHIP_DATA](state, payload) {
    state.membership = payload
  },
  [MARK_GOAL_STEPPER_COMPLETE](state, payload) {
    state.campGoalsProgressStatus = payload
  }
}

export const getters = {
  getPlacementsMonthlyAllocationData(state) {
    return state.placementsMonthlyAllocationData
  },
  campGoalsProgressStatus(state) {
    return state.campGoalsProgressStatus
  },
  selectedFiscalYear(state) {
    return state.selectedFiscalYear
  },
  isDashboardPageLoading(state) {
    return state.isDashboardPageLoading
  },
  hasErrorDashboard(state) {
    return state.dashboardError
  },
  lockedGoalsAccessError(state) {
    return state.lockedGoalsAccessError
  },
  getCustomizeMonthlyAllocationData(state) {
    return state.customizeMonthlyAllocationGoalsData
  },
  getDashboardData(state) {
    return state.dashboard
  },
  churchMinistryAndChurchPresentationGoals(state) {
    return state.churchMinistryAndChurchPresentation
  },
  gideonCardBibleGoals(state) {
    return state.gideonCard
  },
  faithFundGoals(state) {
    return state.faithFund
  },
  auxiliaryScriptureGoals(state) {
    return state.auxiliaryScripture
  },
  campDesignatedGoals(state) {
    return state.campDesignated
  },
  placementsGoals(state) {
    return state.placements
  },
  personalWorkersTestamentsGoals(state) {
    return state.personalWorkersTestaments
  },
  membershipGoals(state) {
    return state.membership
  },
  isCampGoalPlanningWindowOpen(state) {
    return state.isCampGoalPlanningWindowOpen
  }
}

export const actions = {
  async setCampGoalsProgressStatus({ commit, dispatch }, { campKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const responseData = await StepperService.getCampGoalsProgress(campKey)
    if (responseData) {
      const completedSteps = []
      const tempMap = new Map()
      const twoStepCheckGoals = [
        GoalShortCodes.ChurchMinistry,
        GoalShortCodes.ChurchPresentation,
        GoalShortCodes.GideonPlacements,
        GoalShortCodes.AuxiliaryPlacements,
        GoalShortCodes.AuxiliaryPWTs,
        GoalShortCodes.GideonPWTs,
        GoalShortCodes.FaithFund,
        GoalShortCodes.BarnabasFund,
        GoalShortCodes.GideonMembership,
        GoalShortCodes.AuxiliaryMembership
      ]
      const getStepperOrderByShortCode = shortCode =>
        new Map([
          [GoalShortCodes.ChurchMinistry, StepperOrderNumber.ChurchMinistry],
          [GoalShortCodes.ChurchPresentation, StepperOrderNumber.ChurchMinistry],
          [GoalShortCodes.GideonCard, StepperOrderNumber.GideonCard],
          [GoalShortCodes.FaithFund, StepperOrderNumber.FaithFund],
          [GoalShortCodes.AuxiliaryScripture, StepperOrderNumber.AuxiliaryScripture],
          [GoalShortCodes.CampDesignated, StepperOrderNumber.CampDesignated],
          [GoalShortCodes.GideonPlacements, StepperOrderNumber.Placements],
          [GoalShortCodes.AuxiliaryPlacements, StepperOrderNumber.Placements],
          [GoalShortCodes.AuxiliaryPWTs, StepperOrderNumber.PersonalWorkersTestaments],
          [GoalShortCodes.GideonPWTs, StepperOrderNumber.PersonalWorkersTestaments],
          [GoalShortCodes.FaithFund, StepperOrderNumber.FaithFund],
          [GoalShortCodes.BarnabasFund, StepperOrderNumber.FaithFund],
          [GoalShortCodes.GideonMembership, StepperOrderNumber.Membership],
          [GoalShortCodes.AuxiliaryMembership, StepperOrderNumber.Membership]
        ]).get(shortCode)
      responseData &&
        responseData.forEach(itm => {
          if (itm.hasOwnProperty('IsModified')) {
            if (twoStepCheckGoals.includes(itm.ShortCode)) {
              tempMap.set(itm.ShortCode, itm.IsModified)
            } else if (itm.IsModified) {
              completedSteps.push(getStepperOrderByShortCode(itm.ShortCode))
            }
          }
        })
      if (tempMap.get(GoalShortCodes.ChurchMinistry) && tempMap.get(GoalShortCodes.ChurchPresentation)) {
        completedSteps.push(StepperOrderNumber.ChurchMinistry)
      }
      if (tempMap.get(GoalShortCodes.GideonPlacements) && tempMap.get(GoalShortCodes.AuxiliaryPlacements)) {
        completedSteps.push(StepperOrderNumber.Placements)
      }
      if (tempMap.get(GoalShortCodes.AuxiliaryPWTs) && tempMap.get(GoalShortCodes.GideonPWTs)) {
        completedSteps.push(StepperOrderNumber.PersonalWorkersTestaments)
      }
      if (tempMap.get(GoalShortCodes.FaithFund) && tempMap.get(GoalShortCodes.BarnabasFund)) {
        completedSteps.push(StepperOrderNumber.FaithFund)
      }
      if (tempMap.get(GoalShortCodes.GideonMembership) && tempMap.get(GoalShortCodes.AuxiliaryMembership)) {
        completedSteps.push(StepperOrderNumber.Membership)
      }
      commit(MARK_GOAL_STEPPER_COMPLETE, completedSteps)
    }
    dispatch('menu/setLoadingStatus', false, { root: true })
  },
  async downloadCampLockGoalsReport({ commit }, { campKey, fiscalYear, campName }) {
    await DashboardService.downloadCampGoalsReport(campKey, fiscalYear, campName)
  },

  // #region  FETCHING CAMP GOALS DATA AND SETTING GLOBAL STATE
  async setCampGoalDashboardData({ commit, state, dispatch }, { campKey, langKey ,stateOrgKey}) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const responseData = await DashboardService.getCampDashboardData(campKey, langKey,stateOrgKey)
    if (responseData) {
      commit('SET_DASHBOARD_DATA', responseData)
      // setting camp goal planning window  flag
      responseData.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, responseData.IsCampGoalPlanningWindowOpen)
      // setting state goal planning window  flag
      responseData.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', responseData.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      // setting selected fiscal year
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(
          responseData.hasOwnProperty('CampInfo') && responseData.CampInfo.hasOwnProperty('GoalYear')
            ? responseData.CampInfo.GoalYear
            : new Date().getFullYear() + 1
        )
      )
      if (
        responseData.hasOwnProperty('CampGoalsData') &&
        (responseData.CampGoalsData === null || responseData.CampGoalsData.length === 0)
      ) {
        state.dashboardError = true
      } else {
        state.dashboardError = false
      }
      dispatch('menu/setLoadingStatus', false, { root: true })
    } else {
      dispatch('menu/setLoadingStatus', false, { root: true })
      state.dashboardError = true
    }
  },
  async campGoalsLockOrUnlock({ commit, dispatch }, { campKey, fiscalYear, isUserWantsToLockGoals, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await DashboardService.lockOrUnlockCampGoals({ campKey, fiscalYear, isUserWantsToLockGoals })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async setChurchMinistryAndChurchPresentationGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const data = await ChurchMinistryAndChurchPresentationService.getGoals(campKey, langKey)
    dispatch('menu/setLoadingStatus', false, { root: true })
    if (data) {
      commit(SET_CHURCH_MINISTRY_AND_CHURCH_PRESENTATION, getRightDataFormatForReceiptsCategory(data))
      // setting camp goal window planning flag
      data.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, data.IsCampGoalPlanningWindowOpen)
      // setting state goal planning window  flag
      data.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', data.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      // setting selected fiscal year
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(data.hasOwnProperty('FiscalYear') ? data.FiscalYear : new Date().getFullYear() + 1)
      )
    }
  },
  async setGideonCardGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const data = await GideonCardBibleService.getGoals(campKey, langKey)
    dispatch('menu/setLoadingStatus', false, { root: true })
    if (data) {
      commit(SET_GIDEON_CARD_DATA, getRightDataFormatForReceiptsCategory(data))
      // setting camp goal window planning flag
      data.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, data.IsCampGoalPlanningWindowOpen)

      // setting state goal planning window  flag
      data.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', data.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      // setting selected fiscal year
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(data.hasOwnProperty('FiscalYear') ? data.FiscalYear : new Date().getFullYear() + 1)
      )
    }
  },
  async setFaithFundGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const data = await FaithFundService.getGoals(campKey, langKey)
    if (data) {
      commit(SET_FAITH_FUND_DATA, getRightDataFormatForReceiptsCategory(data))
      // setting camp goal window planning flag
      data.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, data.IsCampGoalPlanningWindowOpen)
      // setting state goal planning window  flag
      data.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', data.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      // setting selected fiscal year
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(data.hasOwnProperty('FiscalYear') ? data.FiscalYear : new Date().getFullYear() + 1)
      )
    }
    dispatch('menu/setLoadingStatus', false, { root: true })
  },
  async setAuxiliaryScriptureGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const data = await AuxiliaryScriptureService.getGoals(campKey, langKey)
    dispatch('menu/setLoadingStatus', false, { root: true })
    if (data) {
      commit(SET_AUXILIARY_SCRIPTURE_DATA, getRightDataFormatForReceiptsCategory(data))
      // setting camp goal window planning flag
      data.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, data.IsCampGoalPlanningWindowOpen)
      // setting state goal planning window  flag
      data.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', data.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      // setting selected fiscal year
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(data.hasOwnProperty('FiscalYear') ? data.FiscalYear : new Date().getFullYear() + 1)
      )
    }
  },
  async setCampDesignatedGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const data = await CampDesignatedService.getGoals(campKey, langKey)
    dispatch('menu/setLoadingStatus', false, { root: true })
    if (data) {
      commit(SET_CAMP_DESIGNATED_DATA, getRightDataFormatForReceiptsCategory(data))
      // setting camp goal window planning flag
      data.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, data.IsCampGoalPlanningWindowOpen)

      // setting state goal planning window  flag
      data.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', data.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      // setting selected fiscal year
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(data.hasOwnProperty('FiscalYear') ? data.FiscalYear : new Date().getFullYear() + 1)
      )
    }
    commit(SET_CAMP_DESIGNATED_DATA, getRightDataFormatForReceiptsCategory(data))
  },
  async setPlacementsGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await PlacementsService.getGoals(campKey, langKey)
    if (response) {
      commit(SET_PLACEMENTS_DATA, getRightDataFormatForNonReceiptsCategory(response))
      // resetting placements monthly allocation data
      commit(RESET_PLACEMENTS_MONTHLY_ALLOCATION_DATA)
      // setting camp goal window planning flag
      response.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, response.IsCampGoalPlanningWindowOpen)

      // setting state goal planning window  flag
      response.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', response.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(response.hasOwnProperty('FiscalYear') ? response.FiscalYear : new Date().getFullYear() + 1)
      )
    }
    dispatch('menu/setLoadingStatus', false, { root: true })
  },
  async setPersonalWorkersTestamentsGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await PersonalWorkersTestamentsService.getGoals(campKey, langKey)
    if (response) {
      commit(SET_PERSONAL_WORKERS_TESTAMENTS_DATA, getRightDataFormatForNonReceiptsCategory(response))
      commit(RESET_PWTs_MONTHLY_ALLOCATION_DATA)
      // setting camp goal window planning flag
      response.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, response.IsCampGoalPlanningWindowOpen)
      // setting state goal planning window  flag
      response.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', response.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(response.hasOwnProperty('FiscalYear') ? response.FiscalYear : new Date().getFullYear() + 1)
      )
    }
    dispatch('menu/setLoadingStatus', false, { root: true })
  },
  async setMembershipGoals({ commit, dispatch }, { campKey, langKey }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await MembershipService.getGoals(campKey, langKey)
    if (response) {
      commit(SET_MEMBERSHIP_DATA, getRightDataFormatForReceiptsCategory(response))
      // setting camp goal window planning flag
      response.hasOwnProperty('IsCampGoalPlanningWindowOpen') &&
        commit(SET_IS_CAMP_GOAL_PLANNING_WINDOW_OPEN, response.IsCampGoalPlanningWindowOpen)
      // setting state goal planning window  flag
      response.hasOwnProperty('IsStateGoalPlanningWindowOpen') &&
        dispatch('stateGoalPlanner/setIsStateGoalPlanningWindowOpen', response.IsStateGoalPlanningWindowOpen, {
          root: true
        })
      commit(
        SET_SELECTED_FISCAL_YEAR,
        YearRange(response.hasOwnProperty('FiscalYear') ? response.FiscalYear : new Date().getFullYear() + 1)
      )
    }
    dispatch('menu/setLoadingStatus', false, { root: true })
  },
  // #endregion

  // #region UPDATING GOALS DATA
  async sendChurchMinistryAndPlacementsGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await ChurchMinistryAndChurchPresentationService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendGideonCardBibleGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await GideonCardBibleService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendFaithFundGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await FaithFundService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
    // call service for updating corresponding goals data
  },
  async sendAuxiliaryScriptureGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await AuxiliaryScriptureService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendCampDesignatedGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CampDesignatedService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendPlacementsGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await PlacementsService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendPersonalWorkersTestamentsGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await PersonalWorkersTestamentsService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendMembershipGoalsData({ commit, dispatch }, payload) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await MembershipService.updateGoals({ goalsData: payload })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  // #endregion

  // #region CUSTOMIZE MONTHLY ALLOCATION
  async setCustomizeMonthlyAllocationData({ commit }, { campKey, langKey, routeCampGoal }) {
    const data = await CustomizeMonthlyAllocationService.getCustomizedMonthlyAllocationData({
      routeCampGoal,
      campKey,
      langKey
    })
    if (data.length > 0) {
      commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, getRightDataFormatForCustomizedMonthlyAllocation(data))
    }
  },

  async setCustomizeMonthlyAllocationDataForPlacements({ commit, state, dispatch }, { campKey, langKey, shortCode }) {
    if (
      state.placementsMonthlyAllocationData.hasOwnProperty(shortCode) &&
      state.placementsMonthlyAllocationData[shortCode].campKey === campKey
    ) {
      await commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, state.placementsMonthlyAllocationData[shortCode].data)
      return
    }
    const data = await CustomizeMonthlyAllocationService.getCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.Placements,
      campKey,
      langKey,
      shortCode: shortCode
    })
    if (data.length > 0) {
      await commit(SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA, {
        shortCode,
        toSaveData: { campKey, data: getRightDataFormatForCustomizedMonthlyAllocation(data) }
      })
      await commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, getRightDataFormatForCustomizedMonthlyAllocation(data))
    } else {
      await commit(SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA, {
        shortCode,
        toSaveData: { campKey, data: {} }
      })
      await commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, {})
    }
  },
  resetPlacementsCustomizeMonthlyAllocationData({ commit }) {
    commit(RESET_PLACEMENTS_MONTHLY_ALLOCATION_DATA)
  },

  setPlacementsMonthlyAllocationDataAfterSaved({ commit, state }, { shortCode, toSaveCustomizedMAData }) {
    commit(SET_PLACEMENTS_MONTHLY_ALLOCATION_DATA_AFTER_SAVED, { shortCode, toSaveCustomizedMAData })
  },

  async setCustomizeMonthlyAllocationDataForPWTs({ commit, state }, { campKey, langKey, shortCode }) {
    if (
      state.personalWorkersTestamentsMonthlyAllocationData.hasOwnProperty(shortCode) &&
      state.personalWorkersTestamentsMonthlyAllocationData[shortCode].campKey === campKey
    ) {
      await commit(
        SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA,
        state.personalWorkersTestamentsMonthlyAllocationData[shortCode].data
      )
      return
    }
    const data = await CustomizeMonthlyAllocationService.getCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.PersonalWorkersTestaments,
      campKey,
      langKey,
      shortCode: shortCode
    })
    if (data.length > 0) {
      await commit(SET_PWTs_MONTHLY_ALLOCATION_DATA, {
        shortCode,
        toSaveData: { campKey, data: getRightDataFormatForCustomizedMonthlyAllocation(data) }
      })
      await commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, getRightDataFormatForCustomizedMonthlyAllocation(data))
    } else {
      await commit(SET_PWTs_MONTHLY_ALLOCATION_DATA, {
        shortCode,
        toSaveData: { campKey, data: {} }
      })
      await commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, {})
    }
  },
  resetPWTsCustomizeMonthlyAllocationData({ commit }) {
    commit(RESET_PWTs_MONTHLY_ALLOCATION_DATA)
  },
  setPWTsMonthlyAllocationDataAfterSaved({ commit, state }, { shortCode, toSaveCustomizedMAData }) {
    commit(SET_PWTs_MONTHLY_ALLOCATION_DATA_AFTER_SAVED, { shortCode, toSaveCustomizedMAData })
  },

  // will set customized monthly allocation state so that we don't need to call API call as data are same
  setCustomizedMonthlyAllocationAfterSuccessfullySaved({ commit }, payload) {
    commit(SET_CUSTOMIZE_MONTHLY_ALLOCATION_DATA, payload)
  },
  async sendChurchMinistryCustomizeMonthlyAllocationData({ commit, dispatch }, { churchMinistryMAData }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CustomizeMonthlyAllocationService.updateCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation,
      customizedMAData: churchMinistryMAData
    })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendGideonCardCustomizeMonthlyAllocationData({ commit, dispatch }, { gideonCardMAData }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CustomizeMonthlyAllocationService.updateCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.GideonCard,
      customizedMAData: gideonCardMAData
    })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendAuxiliaryScriptureMonthlyAllocationData({ commit, dispatch }, { auxiliaryScriptureMAData }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CustomizeMonthlyAllocationService.updateCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.AuxiliaryScripture,
      customizedMAData: auxiliaryScriptureMAData
    })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendPlacementsMonthlyAllocationData({ commit, dispatch }, { placementsMAData }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CustomizeMonthlyAllocationService.updateCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.Placements,
      customizedMAData: placementsMAData
    })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendPWTsMonthlyAllocationData({ commit, dispatch }, { personalWorkersTestamentsMAData }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CustomizeMonthlyAllocationService.updateCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.PersonalWorkersTestaments,
      customizedMAData: personalWorkersTestamentsMAData
    })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  },
  async sendFaithFundAndBarnabasFundMonthlyAllocationData({ commit, dispatch }, { faithFundAndBarnabasFundMAData }) {
    dispatch('menu/setLoadingStatus', true, { root: true })
    const response = await CustomizeMonthlyAllocationService.updateCustomizedMonthlyAllocationData({
      routeCampGoal: PermittedGoalRouteNames.FaithFund,
      customizedMAData: faithFundAndBarnabasFundMAData
    })
    dispatch('menu/setLoadingStatus', false, { root: true })
    return response
  }

  // #endregion
}

// Response data formatter for Receipts goals category
export const getRightDataFormatForReceiptsCategory = responseGoalsData => {
  let tempArr = []
  let lastFYGoal = 0
  for (const [key, goals] of Object.entries(responseGoalsData)) {
    if (
      key !== 'IsCustomizedMonthlyMode' &&
      key !== 'FiscalYear' &&
      key !== 'IsGoalsLocked' &&
      key !== 'IsStateGoalPlanningWindowOpen' &&
      key !== 'IsCampGoalPlanningWindowOpen'
    ) {
      if (goals === null) {
        tempArr.push(
          getFallBackDataForReceiptsCategory(
            key,
            YearRange(responseGoalsData.FiscalYear - 1),
            YearRange(responseGoalsData.FiscalYear - 2)
          )
        )
      } else {
        if (!isNaN(parseInt(goals.FY1Goal))) {
          if (
            goals.ShortCode === GoalShortCodes.ChurchMinistry ||
            goals.ShortCode === GoalShortCodes.GideonCard ||
            goals.ShortCode === GoalShortCodes.CampDesignated ||
            goals.ShortCode === GoalShortCodes.AuxiliaryScripture ||
            goals.ShortCode === GoalShortCodes.FaithFund
          ) {
            lastFYGoal = parseInt(goals.FY1Goal)
          }
        }
        tempArr.push({
          shortCode: goals.ShortCode,
          goalName: getCorrectGoalTitleByShortCode(goals.ShortCode),
          planGoal: goals.PlannedGoal,
          lastFYGoals:
            goals.hasOwnProperty('ActualFY2Goal') &&
            goals.ActualFY2Goal !== null &&
            goals.hasOwnProperty('FY1Goal') &&
            goals.FY1Goal !== null
              ? {
                  fY1Goal: {
                    year: YearRange(responseGoalsData.FiscalYear - 1),
                    goal: goals.FY1Goal
                  },
                  actualFY2Goal: {
                    year: YearRange(responseGoalsData.FiscalYear - 2),
                    goal: goals.ActualFY2Goal
                  }
                }
              : {},
          goalInfo: { info: GoalHelpInfo[goals.ShortCode], label: GoalHelpInfo.labels[goals.ShortCode] }
        })
      }
    }
  }

  return {
    isMACustomized: responseGoalsData.IsCustomizedMonthlyMode,
    goals: tempArr,
    fY1Goal: {
      year: YearRange(responseGoalsData.FiscalYear - 1),
      goal: FormatNumber(lastFYGoal)
    },
    areGoalsLocked: responseGoalsData.hasOwnProperty('IsGoalsLocked') && responseGoalsData.IsGoalsLocked
  }
}
// Response data formatter for Non-Receipts goals category
export const getRightDataFormatForNonReceiptsCategory = responseGoalsData => {
  let gideonFiscalYear1GoalsTotal = 0
  let auxiliaryFiscalYear1GoalsTotal = 0
  let gideonArr = []
  let auxiliaryArr = []
  for (const [key, goal] of Object.entries(responseGoalsData)) {
    if (
      key !== 'IsCustomizedMonthlyMode' &&
      key !== 'FiscalYear' &&
      key !== 'IsGoalsLocked' &&
      key !== 'IsStateGoalPlanningWindowOpen' &&
      key !== 'IsCampGoalPlanningWindowOpen'
    ) {
      if (key === 'Medical' || key === 'AuxiliaryFacilities' || key === 'AuxiliaryPWT') {
        if (goal) {
          auxiliaryArr.push({
            goalName: getCorrectGoalTitleByShortCode(goal.ShortCode),
            planGoal: parseInt(goal.PlannedGoal),
            fiscalYear1Goal: parseInt(goal.FY1Goal),
            shortCode: goal.ShortCode
          })
        } else {
          auxiliaryArr.push(getFallBackDatForNonReceiptsCategory(key))
        }
      } else if (key === 'GideonPlacement') {
        gideonFiscalYear1GoalsTotal = goal
          ? goal.hasOwnProperty('ActualFY1Goal') && parseInt(goal.ActualFY1Goal)
          : getFallBackDatForNonReceiptsCategory(key).fiscalYear1Goal
      } else if (key === 'AuxiliaryPlacement') {
        auxiliaryFiscalYear1GoalsTotal = goal
          ? goal.hasOwnProperty('ActualFY1Goal') && parseInt(goal.ActualFY1Goal)
          : getFallBackDatForNonReceiptsCategory(key).fiscalYear1Goal
      } else {
        if (goal) {
          gideonArr.push({
            goalName: getCorrectGoalTitleByShortCode(goal.ShortCode),
            planGoal: parseInt(goal.PlannedGoal),
            fiscalYear1Goal: parseInt(goal.FY1Goal),
            shortCode: goal.ShortCode
          })
        } else {
          gideonArr.push(getFallBackDatForNonReceiptsCategory(key))
        }
      }
    }
  }

  return {
    areGoalsLocked: responseGoalsData.hasOwnProperty('IsGoalsLocked') && responseGoalsData.IsGoalsLocked,
    gideon: {
      goalsData: gideonArr,
      fiscalYear1GoalsTotal: gideonFiscalYear1GoalsTotal
    },
    auxiliary: {
      goalsData: auxiliaryArr,
      fiscalYear1GoalsTotal: auxiliaryFiscalYear1GoalsTotal
    }
  }
}
export const getRightDataFormatForCustomizedMonthlyAllocation = customizedMAData => {
  const tempMap = new Map()
  let column2PlanningGoalsTotal = 0
  let column1PlanningGoalsTotal = 0
  let column1LastFYActualTotal = 0
  let column2LastFYActualTotal = 0
  let tempArray = []

  const correctedResponse = {}
  const isGoalTypeChurchMinistry = customizedMAData[0].ShortCode === GoalShortCodes.ChurchMinistry
  const isGoalTypeFaithFundOrBarnabasFund =
    customizedMAData[0].ShortCode === GoalShortCodes.FaithFund ||
    customizedMAData[0].ShortCode === GoalShortCodes.BarnabasFund
  customizedMAData.forEach(itm => {
    if (itm.ShortCode === GoalShortCodes.ChurchPresentation || itm.ShortCode === GoalShortCodes.FaithFund) {
      column1PlanningGoalsTotal += parseInt(itm.MonthlyProposedGoal)
      column1LastFYActualTotal += parseInt(itm.MonthlyActualGoal)
      tempMap.set(itm.MonthName, {
        column1PlanningGoal: itm.MonthlyProposedGoal,
        column1LastFYActual: itm.MonthlyActualGoal
      })
    } else {
      column2PlanningGoalsTotal += parseInt(itm.MonthlyProposedGoal)
      column2LastFYActualTotal += parseInt(itm.MonthlyActualGoal)
      tempArray.push({
        period: itm.MonthName,
        column1PlanningGoal: null,
        column2PlanningGoal: itm.MonthlyProposedGoal,
        column1LastFYActual: null,
        column2LastFYActual: itm.MonthlyActualGoal
      })
    }
  })
  if (isGoalTypeChurchMinistry || isGoalTypeFaithFundOrBarnabasFund) {
    for (let i = 0; i < tempArray.length; i++) {
      tempArray[i].column1PlanningGoal = tempMap.get(tempArray[i].period).column1PlanningGoal
      tempArray[i].column1LastFYActual = tempMap.get(tempArray[i].period).column1LastFYActual
    }

    correctedResponse['customizedMAData'] = tempArray
    correctedResponse['column2PlanningGoalsTotal'] = column2PlanningGoalsTotal
    correctedResponse['column1PlanningGoalsTotal'] = column1PlanningGoalsTotal
    correctedResponse['column1LastFYActualTotal'] = column1LastFYActualTotal
    correctedResponse['column2LastFYActualTotal'] = column2LastFYActualTotal
  } else {
    correctedResponse['customizedMAData'] = tempArray
    correctedResponse['column2PlanningGoalsTotal'] = column2PlanningGoalsTotal
    correctedResponse['column2LastFYActualTotal'] = column2LastFYActualTotal
  }
  return correctedResponse
}
export const getFallBackDatForNonReceiptsCategory = responseKeyParameter => {
  const fallBackData = {
    AuxiliaryFacilities: {
      goalName: 'Auxiliary Facilities',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.AuxiliaryFacilities
    },
    Bibles: {
      goalName: 'Bible',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.Bible
    },
    College: {
      goalName: 'College ',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.College
    },
    GideonFacilities: {
      goalName: 'Gideon Facilities',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.GideonFacilities
    },
    Hospital: {
      goalName: 'Hospital',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.Hospital
    },
    Medical: {
      goalName: 'Medical',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.Medical
    },
    Service: {
      goalName: 'Emergency Service',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.Service
    },
    SidewalkYouth: {
      goalName: 'Sidewalk Youth',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.SidewalkYouth
    },
    SoftCoverBible: {
      goalName: 'Soft Cover Bible',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.SoftCoverBible
    },
    Youth: {
      goalName: 'Youth',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.Youth
    },
    GideonPlacement: {
      fiscalYear1Goal: 0
    },
    AuxiliaryPlacement: {
      fiscalYear1Goal: 0
    },
    AuxiliaryPWT: {
      goalName: 'Auxiliary PWT',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.AuxiliaryPWTs
    },
    GideonPWT: {
      goalName: 'Gideon PWT',
      planGoal: 0,
      fiscalYear1Goal: 0,
      shortCode: GoalShortCodes.GideonPWTs
    }
  }
  return fallBackData[responseKeyParameter]
}
export const getFallBackDataForReceiptsCategory = (responseKeyParameter, FY1YearRange, FY2YearRange) => {
  const fallBackData = {
    ChurchMinistry: {
      shortCode: GoalShortCodes.ChurchMinistry,
      goalName: 'Church Ministry Goal',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.ChurchMinistry],
        label: GoalHelpInfo.labels[GoalShortCodes.ChurchMinistry]
      }
    },
    ChurchPresentation: {
      shortCode: GoalShortCodes.ChurchPresentation,
      goalName: 'Church Presentation Goal',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.ChurchPresentation],
        label: GoalHelpInfo.labels[GoalShortCodes.ChurchPresentation]
      }
    },
    GideonCardPresentationGoal: {
      shortCode: GoalShortCodes.GideonCardPresentation,
      goalName: 'GideonCard Presentations',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.GideonCardPresentation],
        label: GoalHelpInfo.labels[GoalShortCodes.GideonCardPresentation]
      }
    },
    GideonCardCollectionGoal: {
      shortCode: GoalShortCodes.GideonCardCollection,
      goalName: 'GideonCard Collection Goal',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.GideonCardCollection],
        label: GoalHelpInfo.labels[GoalShortCodes.GideonCardCollection]
      }
    },
    GideonsCardReceiptGoal: {
      shortCode: GoalShortCodes.GideonCard,
      goalName: 'GideonCard Receipt Goal',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.GideonCard],
        label: GoalHelpInfo.labels[GoalShortCodes.GideonCard]
      }
    },
    AuxiliaryScripture: {
      shortCode: GoalShortCodes.AuxiliaryScripture,
      goalName: 'Total Auxiliary Scripture',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.AuxiliaryScripture],
        label: GoalHelpInfo.labels[GoalShortCodes.AuxiliaryScripture]
      }
    },
    TotalCampDesignated: {
      shortCode: GoalShortCodes.CampDesignated,
      goalName: 'Total Camp Designated',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.CampDesignated],
        label: GoalHelpInfo.labels[GoalShortCodes.CampDesignated]
      }
    },
    FaithFund: {
      shortCode: GoalShortCodes.FaithFund,
      goalName: 'Faith Fund',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.FaithFund],
        label: GoalHelpInfo.labels[GoalShortCodes.FaithFund]
      }
    },
    BarnabasFund: {
      shortCode: GoalShortCodes.BarnabasFund,
      goalName: 'Barnabas Fund',
      planGoal: 0,
      lastFYGoals: {
        fY1Goal: {
          year: FY1YearRange,
          goal: 0
        },
        actualFY2Goal: {
          year: FY2YearRange,
          goal: 0
        }
      },
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.BarnabasFund],
        label: GoalHelpInfo.labels[GoalShortCodes.BarnabasFund]
      }
    },
    GideonMembership: {
      shortCode: GoalShortCodes.GideonMembership,
      goalName: 'Gideon',
      planGoal: 2,
      lastFYGoals: {},
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.GideonMembership],
        label: GoalHelpInfo.labels[GoalShortCodes.GideonMembership]
      }
    },
    AuxiliaryMembership: {
      shortCode: GoalShortCodes.AuxiliaryMembership,
      goalName: 'Auxiliary',
      planGoal: 3,
      lastFYGoals: {},
      goalInfo: {
        info: GoalHelpInfo[GoalShortCodes.AuxiliaryMembership],
        label: GoalHelpInfo.labels[GoalShortCodes.AuxiliaryMembership]
      }
    }
  }
  return fallBackData[responseKeyParameter]
}
export const getCorrectGoalTitleByShortCode = shortCode => {
  const correctGoalTitles = {
    [GoalShortCodes.GideonCard]: 'GideonCard Receipt Goal',
    [GoalShortCodes.GideonCardPresentation]: 'GideonCard Presentations',
    [GoalShortCodes.GideonCardCollection]: 'GideonCard Collections Goal',
    [GoalShortCodes.ChurchMinistry]: 'Church Ministry Goal',
    [GoalShortCodes.ChurchPresentation]: 'Church Presentation Goal',
    [GoalShortCodes.CampDesignated]: 'Total Camp Designated',
    [GoalShortCodes.AuxiliaryScripture]: 'Total Auxiliary Scripture',
    [GoalShortCodes.Bible]: 'Bible',
    [GoalShortCodes.Hospital]: 'Hospital',
    [GoalShortCodes.College]: 'College',
    [GoalShortCodes.Youth]: 'Youth',
    [GoalShortCodes.SidewalkYouth]: 'Sidewalk Youth',
    [GoalShortCodes.Service]: 'Emergency Service',
    [GoalShortCodes.SoftCoverBible]: 'Soft Cover Bible',
    [GoalShortCodes.GideonFacilities]: 'Gideon Facilities',
    [GoalShortCodes.Medical]: 'Medical',
    [GoalShortCodes.AuxiliaryFacilities9]: 'Auxiliary Facilities',
    [GoalShortCodes.AuxiliaryFacilities]: 'Auxiliary Facilities',
    [GoalShortCodes.GideonPWTs]: 'Gideon PWT',
    [GoalShortCodes.AuxiliaryPWTs]: 'Auxiliary PWT',
    [GoalShortCodes.BarnabasFund]: 'Barnabas Fund',
    [GoalShortCodes.FaithFund]: 'Faith Fund',
    [GoalShortCodes.GideonMembership]: 'Gideon',
    [GoalShortCodes.AuxiliaryMembership]: 'Auxiliary'
  }
  return correctGoalTitles[shortCode]
}
export const campGoalPlanner = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
