import { GoalShortCodes } from '@/constants/GoalShortCodes.js'

const tooltipMessagesByShortCode = new Map([
  [
    GoalShortCodes.ChurchPresentation,
    'How many churches does your camp have the potential to share the Gideon ministry with this year?'
  ],
  [
    GoalShortCodes.Bible,
    `<ul><li>How many hotel rooms, doctor's offices, etc. does your camp have the potential to place a copy of God’s Word in this year?</li>
    <li>Rounded to the nearest carton quantity of 25 </li></ul>`
  ],
  [
    GoalShortCodes.Hospital,
    `<ul><li>How many hospital rooms or nursing homes does your camp have the potential to place a copy of God’s Word in this year?</li>
    <li>Rounded to the nearest carton quantity of 50 </li></ul>`
  ],
  [GoalShortCodes.College, `<ul><li>How many college students’ hands does your camp want to place a Testament in this year?</li>
    <li>Rounded to the nearest carton quantity of 100</li></ul>`],
  [
    GoalShortCodes.Youth,
    `<ul><li>How many students does your camp have the potential to reach with a red Testament this year?</li>
     <li>Rounded to the nearest carton quantity of 100 </li></ul>`
  ],
  [
    GoalShortCodes.SidewalkYouth,
    `<ul><li>How many students does your camp want to hand an orange Testament to this year at fairs, VBS, or on sidewalks? </li>
    <li>Rounded to the nearest carton quantity of 100 </li></ul>`
  ],
  [GoalShortCodes.Service, `<ul><li>How many police, fire personnel, and EMTs can your camp hand a Testament to this year?</li>
     <li>Rounded to the nearest carton quantity of 100</li></ul>`],
  [
    GoalShortCodes.GideonFacilities,
    `<ul><li>How many prisoners, rescue mission residents, and food distribution recipients does your camp want to reach with the Gospel this year?</li>
    <li>Rounded to the nearest carton quantity of 50</li></ul>`
  ],
  [
    GoalShortCodes.Medical,
    `<ul><li>How many medical, administrative, and support personnel in medical facilities does your camp have the potential to reach with a copy of God’s Word this year?</li>
    <li>Rounded to the nearest carton quantity of 50</li></ul>`
  ],
  [
    GoalShortCodes.AuxiliaryFacilities,
    `<ul><li>How many female inmates, domestic violence shelter residents, and staff/patients of crisis pregnancy centers will your camp reach this year? </li>
    <li>Rounded to the nearest carton quantity of 50</li></ul>`
  ],
  [
    GoalShortCodes.AuxiliaryFacilities9,
    `How many female inmates, domestic violence shelter residents, and staff/patients of crisis pregnancy centers will your camp reach this year? `
  ],
  [GoalShortCodes.GideonMembership, `How many new Gideon members will join your camp this year?`],
  [GoalShortCodes.AuxiliaryMembership, `How many new Auxiliary members will join your camp this year?`],
  [
    GoalShortCodes.FaithFund,
    `Consider these opportunities as you plan for your Faith Fund goal:
Annual Pastors Event, Faith Fund Rallies, Countdown 100, Auxiliary HEART Program, Auxiliary Events
`
  ],
  [
    GoalShortCodes.CampDesignated,
    `Your camp may establish an optional Camp Designated goal to help cover the cost of Scriptures placed or distributed in your camp area.`
  ],
  [
    GoalShortCodes.SoftCoverBible,
    `<ul><li>Rounded to the nearest carton quantity of 50</li></ul>`
  ]
])
export const ToolTipsMessage = shortCode => {
  return tooltipMessagesByShortCode.get(shortCode)
}
