<template lang="">
  <div class="card card--mt card--pb-0">
    <div class="main-heading mb-4">
      State Association Goal Planner - {{ this.stateDashboardDetails.StateName }}
      <span class="badge badge--success" v-if="stateDashboardDetails.IsStateLocked"> - STATE IS LOCKED</span>
    </div>

    <div class="widget-bg widget-bg--dir-column mb-4">
      <h4>Plan Fiscal Year - {{ this.stateDashboardDetails.PlanFiscalYear }}</h4>
      <div class="h-row">
        <div class="h-row__col">
          <div class="form-group">
            <label>Region</label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="View all or filtered camp lists."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            <select v-model="selectedRegion" @change="changeFilter" class="form-control w-100">
              <option value="All">All</option>
              <option v-for="region in this.stateDashboardDetails.Regions" v-bind:value="region" :key="region">{{
                region
              }}</option>
            </select>
          </div>
        </div>
        <div class="h-row__col">
          <div class="form-group">
            <label>Area</label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="View all or filtered camp lists."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            <select v-model="selectedArea" @change="changeFilter" class="form-control w-100">
              <option value="All">All</option>
              <option v-for="area in this.stateDashboardDetails.Areas" v-bind:value="area" :key="area">{{
                area
              }}</option>
            </select>
          </div>
        </div>
        <div class="h-row__col">
          <div class="form-group">
            <label>Category</label>
            <select v-model="selectedCategory" @change="handleCategoryChange" class="form-control w-100">
              <optgroup label="Receipts">
                <option value="RC" selected="selected">All Receipts</option>
                <option value="CM">Church Ministry</option>
                <option value="GC">GideonCard</option>
                <option value="FF">International Faith Fund</option>
                <option value="BF">Barnabas Fund</option>
                <option value="AS">Auxilliary Scripture</option>
                <option value="DC">Camp Designated</option>
              </optgroup>
              <optgroup label="ChurchPresentation">
                <option value="CP">Church Presentations</option>
              </optgroup>
              <optgroup label="Placements">
                <option value="PL">Gideon Placements</option>
                <option value="PA">Auxiliary Placements</option>
              </optgroup>
              <optgroup label="PWTs">
                <option value="GP">Gideon PWTs</option>
                <option value="AP">Auxiliary PWTs</option>
              </optgroup>
              <optgroup label="Membership">
                <option value="MG">Gideon Membership</option>
                <option value="MA">Auxiliary Membership</option>
              </optgroup>
            </select>
          </div>
        </div>
      </div>
      <div class="widget-bg__footer">
        <div class="footer__lft-col">
          Applied Filter:
          <ul>
            <li><strong>Region:</strong> {{ filteredRegion }}</li>
            <li><strong>Area:</strong> {{ filteredArea }}</li>
            <li><strong>Category:</strong> {{ filteredCategoryName }}</li>
          </ul>
        </div>
        <div class="footer__rgt-col">
          <button
            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
            title="Users must select filters (Region,Area,Category) and click Apply Filter to view results in the grid."
            type="button"
            class="btn  d-flex flex-row justify-content-end"
            :class="isFilterChanged ? 'btn-primary ' : 'btn-cancel'"
            @click="applyFilterHandler"
          >
            Apply filter
          </button>
          <button class="btn btn-cancel" @click="resetFilter">
            Reset
          </button>
        </div>
      </div>
    </div>
    <h4>Goal Summary</h4>
    <b-row>
      <b-col md="7" sm="8" v-if="showRunStateTargetSection">
        <div class="d-flex input-grp-wrap align-items-start">
          <label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Insert the overall State Goal and select suggested goal will be created for all camps that have not locked."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            State Target Goal
          </label>
          <div class="input-group-wrap">
            <b-input-group :prepend="isDollarValue() ? '$' : ''">
              <GoalBaseInput
                class="form-control text-content-right"
                v-model="stateTargetGoal"
                placeholder="Enter Target Value"
                :maximumDigitsAllowed="stateTargetGoalMaximumBound"
              />
            </b-input-group>
            <div v-if="isRunTargetInvalid" class="error-msg">
              {{ errorMessage }}
            </div>
          </div>
          <button
            type="button"
            @click="runStateTargetGoalHandler"
            :disabled="isRunTargetInvalid || isTargetZero"
            class="btn-ternary"
          >
            Run target
          </button>
          <span
            v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
            title="Spreads the amount inserted in the State Target Goal."
            class="edit-circle"
          >
            <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
          </span>
        </div>
      </b-col>
      <b-col md="5" sm="4">
        <div class="pr-widget">
          <label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Displays a running total of all goals."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            Current Camp Goal Total
          </label>
          <span> {{ currentCampTotal }}</span>
        </div>
        <div class="pr-widget">
          <label>
            <span
              v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
              title="Displays a running total of only locked camps."
              class="edit-circle"
            >
              <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
            </span>
            Camp Locked Goal Total
          </label>
          <span> {{ lockedCampTotal }}</span>
        </div>
      </b-col>
    </b-row>
    <div>
      <div class="planner-header">
        <h4>FINALIZE GOAL PLANNING <span>Lock Your Goal</span></h4>
      </div>
      <div>
        <div class="g-panel__lft">
          <p class="lft__width">
            Select the Lock All Goals button to finalize goal planning. State planning must be completed and finished by
            <strong>{{ statePlanningCompletionDate }}</strong>
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="!this.stateDashboardDetails.IsStateLocked"
      class="widget-bg justify-content-between mt-3 mb-2 widget-bg--lg-content"
    >
      <div class="d-flex align-items-center">
        <span
          v-b-tooltip.hover="{ customClass: 'custom-tooltip' }"
          title="Locking finalizes and submits all goal planning."
          class="edit-circle"
        >
          <img src="@/assets/svgs/goal-planner/info_ic.svg" alt="info" />
        </span>
        <button
          @click="handlePopUp()"
          type="button"
          class="btn btn--yellow btn--style mr-3"
          :disabled="!isStateGoalPlanningWindowOpen"
        >
          Lock All Goals
        </button>
        <a href="javascript:void(0)" class="btn btn-primary mr-3" @click="exportCampStatusReport">Camp Status Report</a>
        <a href="javascript:void(0)" class="btn btn-primary mr-3" @click="exportCategoryCsv">
          Export {{ csvName }} CSV
        </a>
      </div>
      <b-input-group size="sm" prepend="Upload CSV" v-if="!isStateGoalsReadOnlyMode">
        <b-form-file
          v-model="stateUploadCSVFile"
          :state="Boolean(stateUploadCSVFile)"
          accept=".csv"
          placeholder="Choose a file"
          drop-placeholder="Drop file here..."
        ></b-form-file>
        <b-input-group-append is-text>
          <button type="button" @click="stateUploadCSVHandler">
            <img src="@/assets/svgs/goal-planner/upload_ic.svg" alt="upload" />
          </button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div v-else class="widget-bg justify-content-end mt-2 mb-2">
      <button
        @click="lockUnlockStateHandler()"
        type="button"
        class="btn btn--yellow btn--style mr-3"
        :disabled="!isStateGoalPlanningWindowOpen"
      >
        Unlock State
      </button>
      <a href="javascript:void(0)" class="btn btn-primary" @click="exportCampStatusReport">Camp Status Report</a>
    </div>
    <state-planner-table
      ref="tableResetRef"
      :camps="stateCamps"
      :selectedCategory="filteredCategoryStateTablePropsData"
      @state-dashboard-data-changed="refetchDashboardData"
      @triggered-quick-goal-planning-modal="quickGoalPlanningHandler"
    ></state-planner-table>
    <quick-goal-planning
      v-if="modalShow"
      :isModalShow="modalShow"
      @state-dashboard-data-changed="refetchDashboardData"
      @close-modal="openOrCloseModal(false)"
      :camp="selectedCamp"
    ></quick-goal-planning>
  </div>
</template>
<script>
/*eslint-disable*/
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import StatePlannerTable from '@/components/state-goal-planner/StatePlannerTable.vue'
import QuickGoalPlanning from '@/components/state-goal-planner/QuickGoalPlanning.vue'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes'
import { CampGoalsAllowedMaximumDigits } from '@/constants/CampGoalsMaximumBoundConstants.js'
import GoalBaseInput from '@/components/camp-goal-planner/GoalBaseInput.vue'
import { required, numeric } from 'vuelidate/lib/validators'
import CampGoalService from '@/services/goals/CampGoal.js'
import { ALL_RECEIPTS, ALL } from '@/constants/GoalsConstants.js'

export default {
  data() {
    return {
      stateTargetGoal: '',
      stateTargetGoalMaximumBound: CampGoalsAllowedMaximumDigits.StateTargetLimit,
      isFilterChanged: false,
      selectedCamp: null,
      modalShow: false,
      selectedRegion: 'All',
      selectedArea: 'All',
      selectedCategory: GoalShortCodes.Receipts,
      selectedCategoryName: 'All Receipts',
      filteredRegion: 'All',
      filteredArea: 'All',
      filteredCategory: GoalShortCodes.Receipts,
      filteredCategoryName: 'All Receipts',
      currentCampTotal: 0,
      lockedCampTotal: 0,
      stateUploadCSVFile: null,
      csvName: 'Receipts',
      csvTypes: [
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.Receipts
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.ChurchMinistry
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.GideonCard
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.FaithFund
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.AuxiliaryScripture
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.CampDesignated
        },
        {
          key: 'receipts',
          label: 'Receipts',
          shortCode: GoalShortCodes.BarnabasFund
        },
        {
          key: 'churchpresentation',
          label: 'Church Presentation',
          shortCode: GoalShortCodes.ChurchPresentation
        },
        {
          key: 'gideonsplacements',
          label: 'Gideons Placements',
          shortCode: GoalShortCodes.GideonPlacements
        },
        {
          key: 'auxiliaryplacements',
          label: 'Auxillary Placements',
          shortCode: GoalShortCodes.AuxiliaryPlacements
        },
        {
          key: 'gideonpwts',
          label: 'Gideon PWTs',
          shortCode: GoalShortCodes.GideonPWTs
        },
        {
          key: 'auxiliarypwts',
          label: 'Auxiliary PWTs',
          shortCode: GoalShortCodes.AuxiliaryPWTs
        },
        {
          key: 'newgideonmembers',
          label: 'Gideon Membership',
          shortCode: GoalShortCodes.GideonMembership
        },
        {
          key: 'newauxiliarymembers',
          label: 'Auxiliary Membership',
          shortCode: GoalShortCodes.AuxiliaryMembership
        }
      ]
    }
  },
  provide() {
    return {
      isDollarValue: this.isDollarValue,
      isSelectedStateLocked: () => this.stateDashboardDetails.IsStateLocked
    }
  },
  validations: {
    stateTargetGoal: { numeric, required }
  },
  computed: {
    ...mapGetters({
      userStateKey: 'user/userStateKey',
      userId: 'user/userId',
      stateDashboardDetails: 'stateGoalPlanner/stateDashboardDetails',
      isStateGoalPlanningWindowOpen: 'stateGoalPlanner/isStateGoalPlanningWindowOpen',
      stateCamps: 'stateGoalPlanner/stateCampsDetails',
      statePagePreSavedOtherFilters: 'stateGoalPlanner/getStatePageOtherFilters',
      isGoBackToStateBtnActive: 'stateGoalPlanner/getGoBackBtnValue'
    }),
    isStateGoalsReadOnlyMode() {
      return this.stateDashboardDetails.IsStateLocked || !this.isStateGoalPlanningWindowOpen
    },
    statePlanningCompletionDate() {
      return this.stateDashboardDetails.StatePlanningCompletionDate || ''
    },
    //Check for valid number and total is greater or not
    isRunTargetInvalid() {
      const targetGoal = Number(this.stateTargetGoal)
      const campLockedTotal = this.parseNumber(this.lockedCampTotal)
      const isNumberInvalid = isNaN(targetGoal) || this.stateTargetGoal === ''

      const isTargetTooLow = targetGoal <= campLockedTotal || targetGoal === 0
      return isNumberInvalid || isTargetTooLow
    },
    errorMessage() {
      const targetGoal = Number(this.stateTargetGoal)
      const campLockedTotal = this.parseNumber(this.lockedCampTotal)

      if (isNaN(targetGoal)) {
        return 'A number is required.'
      } else if (this.stateTargetGoal === '') {
        return ''
      } else if (targetGoal <= campLockedTotal) {
        return 'Value must be greater than Camp Locked Goal Total.'
      }
      return '' // No error
    },
    isTargetZero() {
      // Return true if targetGoal is zero
      return Number(this.stateTargetGoal) === 0
    },
    showRunStateTargetSection() {
      const membershipShortCodes = [GoalShortCodes.GideonMembership, GoalShortCodes.AuxiliaryMembership]

      return !this.isStateGoalsReadOnlyMode && !membershipShortCodes.includes(this.filteredCategory)
    },
    filteredCategoryStateTablePropsData() {
      return {
        categoryName: this.filteredCategoryName,
        categoryShortCode: this.filteredCategory
      }
    },
    hasStatePlanningCompletionDatePassed() {
      const diff = new Date(this.stateDashboardDetails.StatePlanningCompletionDate) - new Date()
      return diff < 0 ? true : false
    }
  },
  components: {
    StatePlannerTable,
    QuickGoalPlanning,
    GoalBaseInput
  },
  methods: {
    ...mapActions({
      getStateDetails: 'stateGoalPlanner/getStateDashboardDetails',
      getStateCampsDetails: 'stateGoalPlanner/getStateCampsDetails',
      exportCSV: 'stateGoalPlanner/exportCSV',
      downloadCampStatusReport: 'stateGoalPlanner/downloadCampStatusReport',
      getStateCampsDetails: 'stateGoalPlanner/getStateCampsDetails',
      lockUnlockState: 'stateGoalPlanner/lockUnlockState',
      runStateTargetGoal: 'stateGoalPlanner/runStateTargetGoal',
      uploadStateCSV: 'stateGoalPlanner/uploadStateCSV',
      saveStateOtherFilters: 'stateGoalPlanner/saveStateOtherFilters'
    }),

    validateCSVContent(rows) {
      const requiredHeaders = ['CampNbr', 'CampName', 'Region', 'Area', 'Program Category', 'Lock Status']

      let hasHeaderErrors = false
      let hasRowErrors = false

      // Check if there are no rows
      if (rows.length === 0) {
        console.error('Error: The CSV has no rows or is empty.')
        return true // Return error
      }

      // Check headers
      const headers = rows[0].map(header => header.trim())
      requiredHeaders.forEach(header => {
        if (!headers.includes(header)) {
          hasHeaderErrors = true // Header missing
        }
      })

      // Check for any header that includes "Goal(Fill Column I)"
      const goalHeaderExists = headers.some(header => header.includes('Goal(Fill Column I)'))
      if (!goalHeaderExists) {
        hasHeaderErrors = true // Goal header missing
      }

      let validRowFound = false
      // Validate each row
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].map(cell => cell.trim()) // Trim spaces from each cell

        const errors = []

        // Check for completely empty rows
        if (row.every(cell => cell === '')) {
          continue // Skip this iteration if the row is completely empty
        }

        validRowFound = true

        const nonNumericColumns = ['CampNbr', 'CampName', 'Region', 'Area', 'Program Category']

        nonNumericColumns.forEach(columnName => {
          const columnIndex = headers.indexOf(columnName)
          if (columnIndex !== -1) {
            const cellValue = row[columnIndex]
            // Check if the non-numeric column value is empty
            if (cellValue === '') {
              errors.push(`Empty non-numeric value in row ${i + 1} for column ${columnName}`)
            }
          }
        })

        // Check if any headers contain 'Goal(Fill Column I)' and validate their values
        headers.forEach((header, index) => {
          if (header.includes('Goal(Fill Column I)')) {
            const cellValue = row[index]
            if (cellValue === '') {
              errors.push(`Empty value in row ${i + 1} for column: ${header}`)
            } else if (isNaN(cellValue) || isNaN(parseFloat(cellValue))) {
              // Check if the cell value is numeric
              errors.push(`Non-numeric value in row ${i + 1} for column: ${header}`)
            }
          }
        })

        if (errors.length > 0) {
          hasRowErrors = true // At least one valid row found
        }
      }

      // Check if there are any header or row errors
      if (hasHeaderErrors) {
        return true // Set error if there are header issues
      }

      if (!validRowFound) {
        return true // Return error if no valid rows found
      }

      if (hasRowErrors) {
        return true // Set error if row issues exist
      }

      return false
    },

    async readCSVFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = event => {
          const text = event.target.result
          const rows = text.split('\n').map(row => row.split(','))
          resolve(rows)
        }

        reader.onerror = error => {
          reject(error)
        }

        reader.readAsText(file)
      })
    },

    async stateUploadCSVHandler() {
      if ((this.stateUploadCSVFile && this.stateUploadCSVFile.type) === 'text/csv') {
        // Validate the CSV content
        const csvContent = await this.readCSVFile(this.stateUploadCSVFile)
        const validationErrors = this.validateCSVContent(csvContent)

        if (validationErrors) {
          // Show validation errors if any
          Vue.swal({
            title: 'Incorrect Entries',
            text: 'There are incorrect entries in the files. Please check',
            icon: 'warning',
            timer: 5000,
            showConfirmButton: true
          })
          this.stateUploadCSVFile = null
          return
        }

        const response = await this.uploadStateCSV({
          stateOrgKey: this.userStateKey,
          indKey: this.userId,
          file: this.stateUploadCSVFile
        })
        this.stateUploadCSVFile = null
        if (response === 'Success') {
          await this.refetchDashboardData()
        }
      } else {
        Vue.swal({
          title: 'Only file with .csv extension is allowed',
          toast: true,
          position: 'top',
          showConfirmButton: false,
          padding: '12px',
          timer: 5000,
          grow: 'row',
          icon: 'error',
          timerProgressBar: true,
          width: '400px'
        })
        this.stateUploadCSVFile = null
      }
    },
    async runStateTargetGoalHandler() {
      if (this.$v.stateTargetGoal.$invalid) {
        return
      }
      const payload = {
        StateOrgKey: this.userStateKey,
        ShortCode: this.filteredCategory,
        TargetGoal: this.stateTargetGoal
      }

      const response = await this.runStateTargetGoal({ targetGoalData: payload })
      if (response === 'Success') {
        await this.getCampsDetails()
        this.stateTargetGoal = ''
      }
    },
    async applyFilterHandler() {
      await this.getCampsDetails()
      this.resetApplyFilterButtonCSS()
    },
    async refetchDashboardData() {
      await this.getCampsDetails()
    },
    async loadState() {
      await this.getStateDetails({ stateOrgKey: this.userStateKey })
      await this.getCampsDetails()
    },
    async getCampsDetails() {
      await this.getStateCampsDetails({
        stateOrgKey: this.userStateKey,
        region: this.selectedRegion,
        area: this.selectedArea,
        category: this.selectedCategory
      })
      this.filteredRegion = this.selectedRegion
      this.filteredArea = this.selectedArea
      this.filteredCategory = this.selectedCategory
      this.filteredCategoryName = this.selectedCategoryName

      //To Store Filters to apply incase when uer goes to Camp page and wants to come back on state planner
      this.saveStateOtherFilters({
        preSavedFilteredRegion: this.selectedRegion,
        preSavedFilteredArea: this.selectedArea,
        preSavedFilteredCategory: this.selectedCategory,
        preSavedFilteredCategoryName: this.selectedCategoryName,
        preSavedUserStateKey: this.userStateKey
      })

      this.calculateCampsTotal()
      this.getCsvName()
    },
    parseNumber(value) {
      return parseInt(String(value).replace(/[\$,]/g, ''), 10)
    },
    async resetFilter() {
      this.resetFilterValues()
      await this.getCampsDetails()
      this.resetApplyFilterButtonCSS()
      // resetting the planner table state
      if (this.$refs.tableResetRef) {
        this.$refs.tableResetRef.resetState()
      }
    },
    async exportCampStatusReport() {
      await this.downloadCampStatusReport({
        stateOrgKey: this.userStateKey,
        fiscalYear: this.stateDashboardDetails.FiscalYear
      })
    },
    async exportCategoryCsv() {
      let csvType = this.csvTypes.find(x => x.shortCode == this.filteredCategory).key
      await this.exportCSV({
        stateOrgKey: this.userStateKey,
        csvType: csvType,
        fiscalYear: this.stateDashboardDetails.FiscalYear
      })
    },
    async warningPopUpHandler() {
      this.$swal({
        icon: 'warning',
        title: 'Unable to lock goals ?', // Simple title
        html: `<p>Prior to locking the state goal, all camp goals planning must be completed.</p>`,
        showCancelButton: false,
        confirmButtonText: 'Close', // Button text
        width: '600px',
        confirmButtonColor: '#003946',
        customClass: {
          popup: 'warning-popup', // Unique class for this popup
          confirmButton: 'warning-confirm-button' // Unique class for the button
        }
      })
    },
    async lockUnlockStateHandler() {
      const titleMessage = `State Association Goal Planner ${
        this.stateDashboardDetails.IsStateLocked ? 'Unlock' : 'Lock'
      } Message`
      const lockConfirmationMessage = `<p class='confirm-modal-text'>All camp goals in the goal planner will now be locked and goal setting will be completed.</p>
          <p> Select OK to finish goal planning, or Cancel to continue.</p>`
      const unlockConfirmationMessage = `<p class='confirm-modal-text'>State will be unlocked.</p>
          <p> Select OK to unlock state, or Cancel to continue.</p>`
      const modalConfirmationMessage = this.stateDashboardDetails.IsStateLocked
        ? unlockConfirmationMessage
        : lockConfirmationMessage

      this.$swal({
        icon: 'warning',
        title: `<span class="confirmation-modal-header" >${titleMessage}</span>`,
        html: modalConfirmationMessage,
        showCancelButton: true,
        width: '700px'
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await this.lockUnlockState({
            stateOrgKey: this.userStateKey,
            userWantsToLockState: !this.stateDashboardDetails.IsStateLocked
          })
          if (response === 'Success') {
            this.resetFilterValues()
            await this.loadState()
          }
        }
      })
    },
    //For showing Warning Message if all the camps are not complete 100%
    handlePopUp() {
      // preventing user from opening the popup when the button is in disabled state
      if (!this.isStateGoalPlanningWindowOpen) {
        return
      }

      const allCampsCompleted = this.stateCamps.every(item => item.IsModified === true)
      if (allCampsCompleted) {
        this.lockUnlockStateHandler()
      } else {
        this.warningPopUpHandler()
      }
    },

    //Method to implement selected filters to implement later when user come backs to state planner from camp planer
    async loadPreAppliedFilters() {
      const filters = this.statePagePreSavedOtherFilters || {}
      const {
        preSavedFilteredRegion,
        preSavedFilteredArea,
        preSavedFilteredCategory,
        preSavedFilteredCategoryName,
        preSavedUserStateKey
      } = filters

      this.selectedRegion = preSavedFilteredRegion
      this.selectedArea = preSavedFilteredArea
      this.selectedCategory = preSavedFilteredCategory
      this.selectedCategoryName = preSavedFilteredCategoryName

      await this.getStateDetails({ stateOrgKey: preSavedUserStateKey })
      this.applyFilterHandler()
    },

    resetFilterValues() {
      this.selectedRegion = ALL
      this.selectedArea = ALL
      this.selectedCategory = GoalShortCodes.Receipts
      this.selectedCategoryName = ALL_RECEIPTS
      this.stateUploadCSVFile = null
    },
    handleCategoryChange(e) {
      this.changeFilter()
      if (e.target.options.selectedIndex > -1) {
        this.selectedCategoryName = e.target.options[e.target.options.selectedIndex].text
      }
    },
    getCsvName() {
      this.csvName = this.csvTypes.find(x => x.shortCode == this.filteredCategory).label
    },
    calculateCampsTotal() {
      let sumOfLockedCamps = 0
      let sumOfNotLockedCamps = 0
      this.stateCamps.forEach(obj => {
        if (obj.IsCampLocked === true) {
          sumOfLockedCamps += obj.GoalProposed
        } else {
          sumOfNotLockedCamps += obj.GoalProposed
        }
      })

      this.currentCampTotal = this.isDollarValue()
        ? `$${FormatNumber(sumOfLockedCamps + sumOfNotLockedCamps)}`
        : FormatNumber(sumOfLockedCamps + sumOfNotLockedCamps)
      this.lockedCampTotal = this.isDollarValue()
        ? `$${FormatNumber(sumOfLockedCamps)}`
        : FormatNumber(sumOfLockedCamps)
    },
    quickGoalPlanningHandler(camp) {
      this.selectedCamp = camp
      this.openOrCloseModal(true)
    },
    openOrCloseModal(open) {
      this.modalShow = open
    },
    changeFilter() {
      this.isFilterChanged = true
    },
    resetApplyFilterButtonCSS() {
      this.isFilterChanged = false
    },
    isDollarValue(shortCode) {
      let selectedCategory = shortCode == undefined || shortCode == '' ? this.filteredCategory : shortCode
      return CampGoalService.isGoalDollar(selectedCategory)
    }
  },
  watch: {
    userStateKey: {
      async handler() {
        if (this.isGoBackToStateBtnActive) {
          await this.loadPreAppliedFilters()
        } else {
          this.resetFilter()
          // resetting run state target value
          this.stateTargetGoal = ''
          await this.loadState()
        }
      },
      deep: true
    }
  },
  async created() {
    //TO avoid re setting filters and there searched value
    if (this.isGoBackToStateBtnActive) {
      await this.loadPreAppliedFilters()
    } else {
      this.resetFilter()
      await this.loadState()
    }
  }
}
</script>
<style lang="scss">
.input-group-wrap {
  .input-group {
    max-width: 260px;
  }
  .error-msg {
    max-width: 260px;
  }
}
.text-content-right {
  text-align: right;
}

.confirmation-modal-header {
  font-size: 0.98em;
  display: inline-block;
}

.confirm-modal-text {
  margin-bottom: 10px;
  font-size: 0.98em;
}

.confirm-modal-text + p {
  font-size: 0.98em;
}

.main-heading {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 40px;
  letter-spacing: 0px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .badge {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: none;
  }
}

.badge {
  position: relative;
  padding: 0.35rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  font-weight: normal;
}

.badge--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.btn {
  &.btn--style {
    text-transform: capitalize;
    font-weight: normal;
    margin-left: 10px;
    padding: 10px 25px;
  }

  &.btn--yellow {
    background: #fbc301;
    color: #fff;

    &:hover {
      background: #003946;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.input-grp-wrap {
  align-items: center;

  label {
    font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  .error-msg {
    margin: 0 15px;
    display: block;
    color: #df1515;
    font-weight: 600;
    font-size: 13px;
    line-height: 1;
    padding-right: 12px;
    margin-top: 5px;
  }

  .input-group {
    margin: 0 15px;
    width: auto;
    border-radius: 0;

    .input-group-text {
      background: #ececec;
      color: #003946;
      border-radius: 0;
    }
  }
}

.btn-ternary {
  background: #036f88;
  color: #fff;
  font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
  border: none;
  padding: 6px 20px;
  min-height: 38px;
  border-radius: 0.25rem;
  margin-right: 10px;
}

.pr-widget {
  display: flex;
  justify-content: space-between;

  span {
    text-align: right;

    svg {
      margin-right: 5px;
    }
  }
}

.planner-header {
  margin: 30px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin-bottom: 0;

    span {
      font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 700;
      color: #747474;
      text-transform: capitalize;
      display: inline-flex;
      margin-left: 15px;
    }
  }

  .planner-header__lft {
    display: flex;

    a {
      &:first-child {
        margin-right: 13px;
      }
    }

    .btn {
      padding: 11px 13px;
      text-transform: capitalize;

      img {
        margin-right: 5px;
      }
    }
  }
}

.widget-bg {
  background: #f8f8f8;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  display: flex;
  padding: 15px 16px;
  width: 100%;

  &.widget-bg--dir-column {
    flex-direction: column;
  }

  &.widget-bg--lg-content {
    .btn {
      padding: 11px 17px;
    }

    .input-group {
      max-width: 335px;
    }
  }

  .input-group > .custom-file {
    height: 100%;
  }

  .btn-primary {
    font-weight: normal;
    text-transform: capitalize;
  }

  .custom-file {
    .custom-file-input {
      height: auto;
    }

    .custom-file-label {
      height: 100%;
      margin: 0;
      padding: 10px;
      border-color: #003946;
      border-radius: 0;

      &:after {
        display: none;
      }
    }
  }

  .widget-bg__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ebebeb;
    align-items: flex-start;
    padding-top: 10px;

    .footer__lft-col {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      color: #000;

      ul {
        display: flex;
        margin-top: 5px;

        li {
          list-style: none;
          display: inline-flex;
          background: #e3e3e3;
          border: 1px solid #cfcfcf;
          border-radius: 3px;
          padding: 3px 10px;
          margin-left: 10px;
          color: #434343;
          font-size: 14px;
          font-weight: normal;

          strong {
            margin-right: 4px;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .footer__rgt-col {
      display: flex;
    }

    .btn-primary {
      text-transform: capitalize;
      font-weight: normal;
    }

    .btn-cancel {
      border: 2px solid #003946;
      background: #fff;
      margin-left: 15px;
      font-weight: normal;
      text-transform: capitalize;

      &:hover {
        border-color: #003946;
        background: #003946;
        color: #fff;
      }
    }
  }

  .h-row {
    display: flex;
    width: 100%;

    .h-row__col {
      padding: 0 15px;
      width: 33.3336%;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      label {
        width: auto;
        min-width: auto;
        font-weight: normal;
        font-family: 'Open Sans', sans-serif, Helvetica, Arial, sans-serif;
        font-size: 16px;
        line-height: 24px;
        color: #000;
        margin-bottom: 3px;
        margin-right: 0;
        padding-right: 5px;
      }

      .form-control {
        padding: 0.375rem 0.75rem;
        background: #fff;
      }
    }
  }

  .input-group {
    position: relative;
    margin: 0;
    width: auto;
    min-width: 370px;

    .input-group-text {
      background: #ececec;
      color: #003946;
    }

    input[type='text'] {
      min-height: 42px;
    }

    .input-group-append {
      .input-group-text {
        background: #003946;

        button {
          background: transparent;
          border: none;
        }
      }
    }
  }
}

.g-panel {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;

  .g-panel__rgt {
    display: flex;
    align-items: flex-start;

    .btn {
      padding: 11px 13px;
      font-size: 15px;
      line-height: 22px;
      text-transform: capitalize;

      &:first-child {
        margin-right: 12px;
      }

      img {
        margin-right: 3px;
      }
    }
  }
}

.warning-confirm-button {
  background-color: #003946;
  /* Default color */
  color: white;
}

.warning-confirm-button:hover {
  background-color: #fbc301 !important;
  /* Hover color */
}
</style>
