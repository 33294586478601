<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ title }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :showCamp="showCamp" :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->
      <data-table v-if="translations.components" :fields="dataFields" :items="dtMembers" :hiddenItems="hiddenItems"
        :csvUrl="this.iCanSee(this.secured_member_list_export_controls.export_member_list_button) ? reportCSVUrl : null"
        :pdfUrl="this.iCanSee(this.secured_member_list_export_controls.export_member_list_button) ? reportPDFUrl : null"
        :reportName="reportFileName" :campType="campType" :noRecordsMessage="noRecordsMessage"
        :i18n="translations.components" individualType="member" :showIODues="showIODues"
        :showDuesCheckbox="show_dues_checkbox" @pay_members="payIOMembers($event)">
      </data-table>
      <!-- / Data Tables -->
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMembersList.vue'
import { numericMixin } from '@/mixins/numericMixin'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'
import { MembershipStatusFlag } from '@/constants/MembershipStatusFlag.js'

export default {
  name: 'member-list',
  mixins: [numericMixin, securityMixin, translationMixin],
  data() {
    return {
      dues: {
        renewed: 'Renewed',
      },
      dues_column_accessible: false,
      dues_button_accessible: false,
      show_dues_checkbox: false,
      translations: {},
      noRecordsMessage: '',
      hiddenItems: {
        display: false,
      },
      secured_member_list_export_controls: {
        export_member_list_button: '61df0f23-0680-4fdf-a4db-9738ce968847',
      },
      secured_member_list_dues_controls: {
        view_member_dues_column: '3d26c4f6-5605-4e32-bbb7-5499ba5d8c11',
        member_submit_dues_button: '962fe724-4adf-4ea4-83e6-1b63ff5eda11',
        ioc_dues_paid_checkbox: 'eb474f4d-3702-41d7-bb3b-2fe2f3efb62e',
      },
      error_html: '',
      reportName: 'MembersListByOrganization',
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      loadCampType: 'user/loadSelectedCampType',
      loadMembers: 'membership/loadMembers',
      loadMemberListReportUrls: 'membership/loadMemberListReportUrls',
      loadNewMemberListReportUrls: 'membership/loadNewMemberListReportUrls',
      loadOfficerListReportUrls: 'membership/loadOfficerListReportUrls',
      saveIODues: 'membership/saveIODues',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.checkPermissions(),
          await this.loadMemberListReportUrls(),
          await this.loadNewMemberListReportUrls(),
          await this.loadOfficerListReportUrls(),
          await this.loadCampType(),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async checkPermissions() {
      if (['scm', 'cmb', 'dir', 'oth', 'menu', 'xx'].includes(this.$route.path.split('/')[4]) || 'xx') {
        await this.getControlPermissionsFromList(Object.values(this.secured_member_list_dues_controls)).then(() => {
          this.dues_column_accessible = this.determineAccessibility(
            this.secured_member_list_dues_controls.view_member_dues_column
          )
          this.dues_button_accessible = this.determineAccessibility(
            this.secured_member_list_dues_controls.member_submit_dues_button
          )
          this.show_dues_checkbox = this.determineAccessibility(
            this.secured_member_list_dues_controls.ioc_dues_paid_checkbox
          )
        })
      }
    },
    createReportLink(code, out_type) {
      return {
        acof: out_type === 'csv' ? this.officerListReportAsCSVUrl : this.officerListReportAsPDFUrl,
        aux: '', // 'Auxiliary Members',
        cmb: '', //'Camp Members',
        cof: out_type === 'csv' ? this.officerListReportAsCSVUrl : this.officerListReportAsPDFUrl,
        dir: out_type === 'csv' ? this.officerListReportAsCSVUrl : this.officerListReportAsPDFUrl,
        naux: '', // 'New Auxiliary Members',
        new: out_type === 'csv' ? this.newMemberListReportAsCSVUrl : this.newMemberListReportAsPDFUrl, // 'New Members',
        oth: '', //'Other State/Country Members',
        scm: '', // 'State/Country Members',
        sco: '', // 'State/Country Officers',
        menu: out_type === 'csv' ? this.memberListReportAsCSVUrl : this.memberListReportAsPDFUrl,
        xx: out_type === 'csv' ? this.memberListReportAsCSVUrl : this.memberListReportAsPDFUrl,
      }[code || 'xx']
    },
    errorHtml() {
      const unableToProcessDuesErrorMsg = this.translations.tcUnableToProcessDuesError
      const pleaseTryAgainMsg = this.translations.tcPleaseTryAgainOrContactMembershipServices
      const memberServicesMsg = `<<strong>IOMembership@gideons.org</strong>>. <br>`
      const includeMemberInfo = `<i>${this.translations.tcIncludeMemberNameNumberCamp}<i>`
      return `
        ${unableToProcessDuesErrorMsg} ${pleaseTryAgainMsg} ${memberServicesMsg} ${includeMemberInfo}
      `
    },
    loadTranslations() {
      this.noRecordsMessage = this.translations.tcNoMembersWereFound
      this.error_html = this.errorHtml()

      this.dues.renewed = this.translations.tcRenewed
    },
    async payIOMembers(data) {
      try {
        await this.setLoadingStatus(true)
        let members = data.map((d) => {
          return {
            ind_key: d.ind_key,
            camp_key: this.userCampKey,
            paid_date: d.mid_paid_date,
          }
        })
        let payload = {
          change_user_key: this.userLogin.indkey,
          members: [...members],
        }
        let response = false
        await Promise.all([(response = await this.saveIODues(payload)), await this.page_load()])
        if (response === false) {
          this.$swal({
            icon: 'error',
            html: this.error_html,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            this.loadMembers(this.pageCode)
          })
          .then(() => {
              return
          })
        } else if (response.data.response && response.data.response === '0') {
          this.$swal({
            icon: 'error',
            html: this.error_html,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
            .then((result) => {
              console.error('ERROR: ', response.data.message)
              this.loadMembers(this.pageCode)
            })
            .then(() => {
              return
            })
        } else {
          this.$swal({
            icon: 'success',
            text: this.translations.tcMemberDuesMarkedAsPaid,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
            .then((result) => {
              this.loadMembers(this.pageCode)
            })
            .then(() => {
              return
            })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      campType: 'user/userSelectedCampType',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      members: 'membership/members',
      memberListReportAsCSVUrl: 'membership/memberListReportAsCSVUrl',
      memberListReportAsPDFUrl: 'membership/memberListReportAsPDFUrl',
      newMemberListReportAsCSVUrl: 'membership/newMemberListReportAsCSVUrl',
      newMemberListReportAsPDFUrl: 'membership/newMemberListReportAsPDFUrl',
      officerListReportAsCSVUrl: 'membership/officerListReportAsCSVUrl',
      officerListReportAsPDFUrl: 'membership/officerListReportAsPDFUrl',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userLogin: 'user/userLogin',
    }),
    pageCode() {
      const newCode = this.$route.path.split('/')[4] || 'xx'
      if (typeof pageCode === 'undefined' || pageCode !== newCode) {
        this.loadMembers(newCode)
      }
      return newCode
    },
    MembershipStatusFlag(){
      return MembershipStatusFlag
    },
    reportCSVUrl() {
      return this.createReportLink(this.pageCode, 'csv')
    },
    reportPDFUrl() {
      return this.createReportLink(this.pageCode, 'pdf')
    },
    title() {
      return {
        acof: this.translations.tcAuxiliaryOfficersTitle,
        aux: this.translations.tcAuxiliaryMembersTitle,
        cmb: this.translations.tcCampMembersTitle,
        cof: this.translations.tcCampOfficersTitle,
        dir: this.translations.tcCampOfficersTitle,
        naux: this.translations.tcAuxiliaryMembersTitle,
        new: this.translations.tcNewMembersTitle,
        oth: this.translations.tcOtherStateOrCountryMembersTitle,
        scm: this.translations.tcStateOrCountryMembersTitle,
        sco: this.translations.tcStateOrCountryOfficersTitle,
        menu: this.translations.tcMembersTitle,
        xx: this.translations.tcMembersTitle,
      }[this.pageCode || 'xx']
    },
    reportFileName() {
      const returnValue = {
        acof: 'OfficersListByOrganization',
        // aux: this.translations.tcAuxiliaryMembersTitle,
        // cmb: this.translations.tcCampMembersTitle,
        cof: 'OfficersListByOrganization',
        // dir: this.translations.tcCampOfficersTitle,
        // naux: this.translations.tcAuxiliaryMembersTitle,
        new: 'NewMembersListByOrganization',
        // oth: this.translations.tcOtherStateOrCountryMembersTitle,
        // scm: this.translations.tcStateOrCountryMembersTitle,
        // sco: this.translations.tcStateOrCountryOfficersTitle,
        xx: 'MembersListByCamp',
        menu: 'MembersListByCamp',
      }[this.pageCode || 'xx']
      return returnValue
    },
    showIODues() {
      return (
        ['scm', 'cmb', 'dir', 'oth', 'menu', 'xx'].includes(this.$route.path.split('/')[4] || 'xx') &&
        ((this.campType === 'US' && this.dues_column_accessible) || (this.campType === 'IO' && this.show_dues_checkbox))
      )
    },
    showCamp() {
      return this.pageCode !== 'sco'
    },
    dataFields() {
      let indx = this.$route.path.split('/')[4] || 'xx'
      let data_fields = {
        aux: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'join_date', label: this.translations.tcJoinDate, sortable: true },
        ],
        sco: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'office', label: this.translations.tcOffice, sortable: true },
          { key: 'start_date', label: this.translations.tcStartDate, sortable: true },
        ],
        naux: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'join_date', label: this.translations.tcJoinDate, sortable: true },
        ],
        new: [
          { key: 'vin_complete_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          {
            key: 'mbr_join_date',
            label: this.translations.tcJoinDate,
            sortable: true,
            formatter: (value, key, item) => {
              let fdate = new Date(value)
              return !!fdate & (fdate.getFullYear() > 1900) ? fdate.toISOString().substr(0, 10) : ''
            },
          },
        ],
        cof: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'office', label: this.translations.tcOffice, sortable: true },
          { key: 'start_date', label: this.translations.tcStartDate, sortable: true },
        ],
        acof: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'office', label: this.translations.tcOffice, sortable: true },
          { key: 'start_date', label: this.translations.tcStartDate, sortable: true },
        ],
        cmb: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'city', label: this.translations.tcCity, sortable: true },
          { key: 'postal_code', label: this.translations.tcZipCode, sortable: true },
        ],
        scm: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'city', label: this.translations.tcCity, sortable: true },
          { key: 'postal_code', label: this.translations.tcZipCode, sortable: true },
        ],
        dir: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'city', label: this.translations.tcCity, sortable: true },
          { key: 'postal_code', label: this.translations.tcZipCode, sortable: true },
        ],
        oth: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'city', label: this.translations.tcCity, sortable: true },
          { key: 'postal_code', label: this.translations.tcZipCode, sortable: true },
        ],
        xx: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'city', label: this.translations.tcCity, sortable: true },
          { key: 'postal_code', label: this.translations.tcZipCode, sortable: true },
        ],
        menu: [
          { key: 'sort_name', label: this.translations.tcName, sortable: true, thClass: 'name-left' },
          { key: 'city', label: this.translations.tcCity, sortable: true },
          { key: 'postal_code', label: this.translations.tcZipCode, sortable: true },
        ],
      }[indx]

      if (this.showIODues) {
        data_fields.push({
          key: 'dues_paid_flag',
          label: this.userLogin.usa_dues_season || this.userLogin.ioc_dues_season ? this.translations.tcMembershipRenewal : '',
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => {
            // US Camps
            // Renewed/Paid
            if (!item.is_life_time_member && item.dues_paid_flag && this.campType === 'US') {
              //Installment Enabled
              if (!!item.is_installment_member && item.pending_drop_date === null) {
                  return MembershipStatusFlag.InstallmentEnabled;
              } 
              
              //Pending Drop if payment gets failed
              if (item.pending_drop_date !== null) {
                  return MembershipStatusFlag.PendingDrop;
              }
              
              // Renewed/Paid
              if (this.userLogin.usa_dues_season) {
                  return MembershipStatusFlag.Renewed;
              }             
            }

            // Renewed/Autopaid
            if (!item.is_life_time_member && item.dues_paid_flag && this.campType === 'US' && !this.userLogin.usa_dues_season && item.autopay_flag) {
              return MembershipStatusFlag.AutopayEnabled;
            }

            // Life Time
            if (item.is_life_time_member) {
              return MembershipStatusFlag.LifeTime;
            }            

            //Not Renewed/link
            //Pending Drop
            if (
              !item.is_life_time_member &&
              !item.dues_paid_flag &&
              this.campType === 'US' 
            ) {
              if(this.userLogin.usa_dues_season){
                  return MembershipStatusFlag.NotRenewed;
              } else {
                  return MembershipStatusFlag.PendingDrop;
              }
            }
            
            //IO Camps
            // Paid
            if (!item.is_life_time_member && item.dues_paid_flag && this.campType === 'IO') {
              let fDate = !!item.mid_paid_date ? this.formattedDate(new Date(item.mid_paid_date)) : null
              return `Paid${!!fDate ? ' - ' + fDate : ''}`
            }

            // Not Paid -- checkbox
            if (
              !item.is_life_time_member &&
              !item.dues_paid_flag &&
              this.campType === 'IO' &&
              this.show_dues_checkbox &&
              this.userLogin.ioc_dues_season
            ) {              
              return `checkbox`
            }           
          },
        })
      }
      return data_fields
    },
    allowPage() {
      if (!!this.$route.path.split('/')[4] && this.$route.path.split('/')[4] !== 'menu') {
        return this.isInMenu(this.$route.path.replace('/' + this.$route.path.split('/')[4], ''))
      } else {
        return this.isInMenu(this.$route.path)
      }
    },
    dtMembers() {
      return this.members.map((member) => {
        let officeText = member.office
        if (member.hasOwnProperty('office_key')) {
          officeText = this.getTranslatedValue(member.office_key, 'officer-titles', member.office)
        } else if (member.hasOwnProperty('vctp_ctp_key')) {
          officeText = this.getTranslatedValue(member.vctp_ctp_key, 'officer-titles', member.office)
        }

        let updatedMember = {
          ...member,
          cb_paid_flag: 'false',
          office: officeText,
        }
        if (!member.dues_paid_flag) {
          updatedMember.mid_paid_date = null
        }
        return updatedMember
      })
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations('officer-titles'),
        await this.getComponentTranslations('camp-select', 'data-table-members', 'security-banner'),
        await this.getComponentTranslations('common.officer-titles'),
      ]).then((results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
        this.stripReadableText(results[2])
        this.loadTranslations()
      })
    } catch (e) {
      console.error(e)
    } finally {
      setTimeout(() => {
        this.setLoadingStatus(false)
      }, 2000)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
