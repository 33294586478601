<template lang="">
  <b-modal v-model="modalShow" size="xl" title="Monthly Allocation" @close="closeModal" @change="closeModal">
    <div v-if="isModalLoading">
      <b-skeleton animation="wave" width="50%"></b-skeleton>
      <b-skeleton animation="wave" width="20%" height="20%"></b-skeleton>
      <b-skeleton animation="wave" width="40%" height="20%"></b-skeleton>
      <b-skeleton animation="wave" width="24%" height="20%"></b-skeleton>
      <b-skeleton-table :rows="12" :columns="4" :table-props="{ striped: true }"></b-skeleton-table>
    </div>
    <div v-else>
      <div class="g-panel">
        <div class="g-panel__lft">
          <p>The heart of man plans his way, but the LORD establishes his steps. Proverbs 16:9</p>
          <ul>
            <li>{{ officerToolbarSelected.camp_number }} - {{ officerToolbarSelected.camp_name.toUpperCase() }}</li>

            <li>GOAL PLANNING - {{ correctModalTitle }} MONTHLY</li>
            <li>Selected Fiscal Year: {{ selectedFiscalYear }}</li>
          </ul>
        </div>
      </div>
      <b-table responsive class="custom-table" striped hover :items="monthlyAllocationData" :fields="fields">
        <template #cell(column1PlanningGoal)="data">
          <label v-if="isGoalReadOnlyMode" class="form-control form-control-sm locked-label-customize">{{
            column1FormatNumber(data.value)
          }}</label>
          <b-input-group v-else prepend="$" :class="!isColumn1PlanningGoalDollarValue && 'opaque'">
            <GoalBaseInput
              :maximumDigitsAllowed="column1InputMaximumDigit"
              @input="column1InputChangeHandler"
              v-model="monthlyAllocationData[data.index].column1PlanningGoal"
              class="text-right px-2 "
              :class="{
                'error-bordered': hasColumn1PlanningGoalsFormError,
                'error-animate': hasColumn1PlanningGoalsFormError
              }"
            />
          </b-input-group>
          <div v-if="$v.monthlyAllocationData.$each[data.index].column1PlanningGoal.$invalid" class="error">
            Enter a valid value.
          </div>
        </template>
        <template #cell(column2PlanningGoal)="data">
          <label v-if="isGoalReadOnlyMode" class="form-control form-control-sm locked-label-customize">{{
            column2FormatNumber(data.value)
          }}</label>
          <b-input-group v-else prepend="$" :class="!isColumn2PlanningGoalDollarValue && 'opaque'">
            <GoalBaseInput
              :maximumDigitsAllowed="column2InputMaximumDigit"
              class="text-right px-2"
              @input="column2InputChangeHandler"
              :class="{
                'error-bordered': hasColumn2PlanningGoalsFormError,
                'error-animate': hasColumn2PlanningGoalsFormError
              }"
              v-model="monthlyAllocationData[data.index].column2PlanningGoal"
            />
          </b-input-group>
          <div v-if="$v.monthlyAllocationData.$each[data.index].column2PlanningGoal.$invalid" class="error">
            Enter a valid value
          </div>
        </template>
        <template #cell(column1LastFYActual)="data">
          <div class="d-flex">
            <button
              :disabled="isGoalReadOnlyMode"
              @click="column1MonthlyAllocationTransferGoalHandler(data.index, data.value)"
              class="arrow-btn"
            >
              <img src="@/assets/svgs/goal-planner/ic_arrow.svg" alt="arrow" />
            </button>
            {{ column1FormatNumber(data.value) }}
          </div>
        </template>
        <template #cell(column2LastFYActual)="data">
          <div class="d-flex">
            <button
              :disabled="isGoalReadOnlyMode"
              @click="column2MonthlyAllocationTransferGoalHandler(data.index, data.value)"
              class="arrow-btn"
            >
              <img src="@/assets/svgs/goal-planner/ic_arrow.svg" alt="arrow" />
            </button>
            {{ column2FormatNumber(data.value) }}
          </div>
        </template>
        <template #custom-foot>
          <b-tr v-if="isFaithFundPage" class="faith-fund-total-row">
            <b-td><strong>Total</strong></b-td>
            <b-td
              ><strong class="faith-fund-total-row__wd">{{
                column1FormatNumber(calculatedColumn1PlanningGoalsTotal)
              }}</strong>
              <div v-if="hasColumn2PlanningGoalsFormError && hasColumn1PlanningGoalsFormError" class="error-md-center">
                Sum of all faith goals must be equal to its planning goal
                <b>{{ column1FormatNumber(monthlyAllocationTotalData.column1Total) }} </b>.
              </div>
              <div
                v-else-if="hasColumn1InputsTotalBoundError && hasColumn2InputsTotalBoundError"
                class="error-md-center"
              >
                Total faith fund goals cannot exceed
                {{ column1FormatNumber(maximumColumn1PlanningGoalsTotalAllowed) }}.
              </div>
            </b-td>
            <b-td
              ><strong class="faith-fund-total-row__sm-wd">{{ column1LastFYGoalsTotal }}</strong></b-td
            >
            <b-td
              ><strong class="faith-fund-total-row__wd">{{
                column2FormatNumber(calculatedColumn2PlanningGoalsTotal)
              }}</strong>
              <div v-if="hasColumn2PlanningGoalsFormError && hasColumn1PlanningGoalsFormError" class="error-md-center">
                Sum of all barnabas fund goals must be equal to its planning goal
                <b>{{ column2FormatNumber(monthlyAllocationTotalData.column2Total) }} </b>.
              </div>
              <div
                v-else-if="hasColumn2InputsTotalBoundError && hasColumn1InputsTotalBoundError"
                class="error-md-center"
              >
                Total barnabas fund goals cannot exceed
                {{ column2FormatNumber(maximumColumn2PlanningGoalsTotalAllowed) }}.
              </div>
            </b-td>
            <b-td
              ><strong class="faith-fund-total-row__sm-wd">{{ column2LastFYGoalsTotal }}</strong></b-td
            >
          </b-tr>
          <b-tr v-else-if="!isRouteChurchMinistryAndPresentation" class="foot-three-col">
            <b-td><strong>Total</strong></b-td>
            <b-td
              ><strong
                class="foot-three-col__width "
                :class="!isColumn2PlanningGoalDollarValue && 'foot-three-col__width--non-dollar'"
                >{{ column2FormatNumber(calculatedColumn2PlanningGoalsTotal) }}</strong
              >
            </b-td>
            <b-td
              ><strong class="foot-three-col__sm-width">{{ column2LastFYGoalsTotal }}</strong></b-td
            >
          </b-tr>
          <b-tr v-else class="foot-four-col">
            <b-td><strong>Total</strong></b-td>
            <b-td
              ><strong class="foot-four-col__md-width">{{
                column1FormatNumber(calculatedColumn1PlanningGoalsTotal)
              }}</strong>
              <div v-if="hasColumn2PlanningGoalsFormError && hasColumn1PlanningGoalsFormError" class="error-md-center">
                Sum of all presentational goals must be equal to its planning goal
                <b>{{ column1FormatNumber(monthlyAllocationTotalData.column1Total) }} </b>.
              </div>
              <div
                v-else-if="hasColumn1InputsTotalBoundError && hasColumn2InputsTotalBoundError"
                class="error-md-center"
              >
                Total presentational goals cannot exceed
                {{ column1FormatNumber(maximumColumn1PlanningGoalsTotalAllowed) }}.
              </div>
            </b-td>
            <b-td
              ><strong class="foot-four-col__width">{{
                column2FormatNumber(calculatedColumn2PlanningGoalsTotal)
              }}</strong>
              <div v-if="hasColumn2PlanningGoalsFormError && hasColumn1PlanningGoalsFormError" class="error-md-center">
                Sum of all monthly goals must be equal to its planning goal
                <b>{{ column2FormatNumber(monthlyAllocationTotalData.column2Total) }} </b>.
              </div>
              <div
                v-else-if="hasColumn2InputsTotalBoundError && hasColumn1InputsTotalBoundError"
                class="error-md-center"
              >
                Total monthly goals cannot exceed {{ column2FormatNumber(maximumColumn2PlanningGoalsTotalAllowed) }}.
              </div>
            </b-td>
            <b-td
              ><strong class="foot-four-col__sm-width">{{ column2LastFYGoalsTotal }}</strong></b-td
            >
          </b-tr>
        </template>
      </b-table>
      <div v-if="hasColumn2PlanningGoalsFormError && !hasColumn1PlanningGoalsFormError" class="error-md">
        Sum of all {{ isFaithFundPage ? 'barnabas fund' : 'monthly' }} goals must be equal to its planning goal
        <b>{{ column2FormatNumber(monthlyAllocationTotalData.column2Total) }} </b>.
      </div>
      <div v-if="hasColumn1PlanningGoalsFormError && !hasColumn2PlanningGoalsFormError" class="error-md">
        Sum of all {{ isFaithFundPage ? 'faith fund' : 'presentational' }} goals must be equal to its planning goal
        <b>{{ column1FormatNumber(monthlyAllocationTotalData.column1Total) }} </b>.
      </div>
      <div v-if="hasEqualMAValidationErrorAfterSaved" class="error-center">
        Please save to continue.
      </div>
      <div v-if="hasColumn2InputsTotalBoundError && !hasColumn1InputsTotalBoundError" class="error-center">
        Total {{ isFaithFundPage ? 'barnabas fund' : 'monthly' }} goals cannot exceed
        {{ column2FormatNumber(maximumColumn2PlanningGoalsTotalAllowed) }}.
      </div>
      <div v-if="hasColumn1InputsTotalBoundError && !hasColumn2InputsTotalBoundError" class="error-center">
        Total {{ isFaithFundPage ? 'faith fund' : 'presentational' }} goals cannot exceed
        {{ column1FormatNumber(maximumColumn1PlanningGoalsTotalAllowed) }}.
      </div>
    </div>
    <template #modal-footer="{ok,cancel,hide}">
      <button
        type="button"
        v-if="!isGoalReadOnlyMode"
        class="btn btn-primary d-flex flex-row justify-content-end"
        @click="modalSaveHandler"
      >
        Save
      </button>
      <button class="btn btn-cancel" @click="closeModal">
        Cancel
      </button>
    </template>
  </b-modal>
</template>
<script>
/*eslint-disable*/
import { mapGetters, mapActions } from 'vuex'
import { required, numeric } from 'vuelidate/lib/validators'
import { PermittedGoalRouteNames } from '@/constants/PermittedGoalRouteNames.js'
import { YearRange } from '@/services/utils/YearRange.js'
import GoalBaseInput from '@/components/camp-goal-planner/GoalBaseInput.vue'
import { FormatNumber } from '@/services/utils/FormatNumber.js'
import { GoalShortCodes } from '@/constants/GoalShortCodes'
import CampGoalService from '@/services/goals/CampGoal.js'

export default {
  props: {
    column1GoalShortCode: {
      type: [String, null],
      default: null
    },
    column2GoalShortCode: {
      type: [String, null],
      default: null
    },
    isModalShow: {
      type: Boolean,
      required: true
    },
    firstTimeCustomizedMA: {
      type: Boolean,
      default: false
    },
    monthlyAllocationTotalData: {
      type: Object,
      required: true
    },
    isGoalReadOnlyMode: {
      type: Boolean,
      required: true
    },
    hasEqualMAValidationErrorAfterSaved: {
      type: Boolean,
      default: false
    }
  },
  inject: ['goalPageTitle'],
  components: {
    GoalBaseInput
  },
  data() {
    return {
      modalShow: this.isModalShow,
      column2InputValueStartChanging: false,
      column1InputValueStartChanging: false,
      isModalLoading: true,
      fields: [],
      monthlyAllocationData: [
        {
          column1PlanningGoal: 0,
          column2PlanningGoal: 0
        }
      ],
      monthsInOrder: [
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
        'January',
        'February',
        'March',
        'April',
        'May'
      ]
    }
  },

  computed: {
    ...mapGetters({
      getCustomizeMonthlyAllocationData: 'campGoalPlanner/getCustomizeMonthlyAllocationData',
      officerToolbarSelected: 'user/officerToolbarSelected',
      userCampKey: 'user/userCampKey',
      userLanguageKey: 'user/userLanguageKey',
      selectedFiscalYear: 'campGoalPlanner/selectedFiscalYear'
    }),
    column1LastFYGoalsTotal() {
      let total = 0
      this.monthlyAllocationData.forEach(itm => {
        total += itm.column1LastFYActual
      })
      return this.column1FormatNumber(total)
    },
    column2LastFYGoalsTotal() {
      let total = 0
      this.monthlyAllocationData.forEach(itm => {
        total += itm.column2LastFYActual
      })
      return this.column2FormatNumber(total)
    },
    isFaithFundPage() {
      return this.$route.params.campGoalName === PermittedGoalRouteNames.FaithFund
    },
    isColumn1PlanningGoalDollarValue() {
      return CampGoalService.isGoalDollar(this.column1GoalShortCode)
    },
    isColumn2PlanningGoalDollarValue() {
      return CampGoalService.isGoalDollar(this.column2GoalShortCode)
    },
    column1FormatNumber() {
      return value => this.formatNumber(value, this.isColumn1PlanningGoalDollarValue)
    },
    column2FormatNumber() {
      return value => this.formatNumber(value, this.isColumn2PlanningGoalDollarValue)
    },
    isRoutePlacementsOrPWTs() {
      return (
        this.$route.params.campGoalName === PermittedGoalRouteNames.Placements ||
        this.$route.params.campGoalName === PermittedGoalRouteNames.PersonalWorkersTestaments
      )
    },
    hasColumn2InputsTotalBoundError() {
      return this.calculatedColumn2PlanningGoalsTotal > this.maximumColumn2PlanningGoalsTotalAllowed
    },
    hasColumn1InputsTotalBoundError() {
      return this.calculatedColumn1PlanningGoalsTotal > this.maximumColumn1PlanningGoalsTotalAllowed
    },
    maximumColumn2PlanningGoalsTotalAllowed() {
      return CampGoalService.getMaximumTotalAllowed(this.column2GoalShortCode)
    },
    maximumColumn1PlanningGoalsTotalAllowed() {
      return CampGoalService.getMaximumTotalAllowed(this.column1GoalShortCode)
    },
    column1InputMaximumDigit() {
      return CampGoalService.getCampMaximumInputBound(this.column1GoalShortCode)
    },
    column2InputMaximumDigit() {
      return CampGoalService.getCampMaximumInputBound(this.column2GoalShortCode)
    },
    correctModalTitle() {
      const placementsAndPWTsTitles = {
        [GoalShortCodes.Bible]: 'Bible',
        [GoalShortCodes.Hospital]: 'Hospital',
        [GoalShortCodes.College]: 'College',
        [GoalShortCodes.Youth]: 'Youth',
        [GoalShortCodes.SidewalkYouth]: 'Sidewalk Youth',
        [GoalShortCodes.Service]: 'Emergency Service',
        [GoalShortCodes.SoftCoverBible]: 'SoftCover Bible',
        [GoalShortCodes.GideonFacilities]: 'Gideon Facilities',
        [GoalShortCodes.Medical]: 'Medical',
        [GoalShortCodes.AuxiliaryFacilities]: 'Auxiliary Facilities',
        [GoalShortCodes.AuxiliaryFacilities9]: 'Auxiliary Facilities',
        [GoalShortCodes.GideonPWTs]: 'Gideon PWT',
        [GoalShortCodes.AuxiliaryPWTs]: 'Auxiliary PWT'
      }
      return Object.keys(placementsAndPWTsTitles).includes(this.column2GoalShortCode)
        ? placementsAndPWTsTitles[this.column2GoalShortCode].toUpperCase()
        : this.goalPageTitle.toUpperCase()
    },
    hasColumn2InputsValidationError() {
      let isInvalid = false
      new Array(12).fill(0).forEach((_, index) => {
        if (this.$v.monthlyAllocationData.$each[index].column2PlanningGoal.$invalid) {
          isInvalid = true
        }
      })

      return isInvalid
    },
    isRouteChurchMinistryAndPresentation() {
      return this.$route.params.campGoalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation
    },
    hasColumn2PlanningGoalsFormError() {
      return (
        !this.column2InputValueStartChanging &&
        !this.isModalLoading &&
        this.monthlyAllocationTotalData.hasOwnProperty('column2Total') &&
        this.monthlyAllocationTotalData.column2Total !== null &&
        this.monthlyAllocationTotalData.column2Total !== this.calculatedColumn2PlanningGoalsTotal
      )
    },
    hasColumn1PlanningGoalsFormError() {
      return (
        !this.column1InputValueStartChanging &&
        !this.isModalLoading &&
        this.monthlyAllocationTotalData.hasOwnProperty('column1Total') &&
        this.monthlyAllocationTotalData.column1Total !== null &&
        this.monthlyAllocationTotalData.column1Total !== this.calculatedColumn1PlanningGoalsTotal
      )
    },
    calculatedColumn1PlanningGoalsTotal() {
      if (this.monthlyAllocationData.length === 0) return null
      let total = 0
      this.monthlyAllocationData.forEach(itm => {
        if (typeof itm.column1PlanningGoal === 'number') {
          total += itm.column1PlanningGoal
        }
      })
      return Math.round(total)
    },
    calculatedColumn2PlanningGoalsTotal() {
      if (this.monthlyAllocationData.length === 0) return null
      let total = 0
      this.monthlyAllocationData.forEach(itm => {
        if (typeof itm.column2PlanningGoal === 'number') {
          total += itm.column2PlanningGoal
        }
      })
      return Math.round(total)
    }
  },
  validations: {
    monthlyAllocationData: {
      $each: {
        column1PlanningGoal: { numeric, required },
        column2PlanningGoal: { numeric, required }
      }
    }
  },
  methods: {
    ...mapActions({
      setCustomizeMonthlyAllocationDataForPWTs: 'campGoalPlanner/setCustomizeMonthlyAllocationDataForPWTs',
      setPWTsMonthlyAllocationDataAfterSaved: 'campGoalPlanner/setPWTsMonthlyAllocationDataAfterSaved',
      setCustomizeMonthlyAllocationDataForPlacements: 'campGoalPlanner/setCustomizeMonthlyAllocationDataForPlacements',
      setPlacementsMonthlyAllocationDataAfterSaved: 'campGoalPlanner/setPlacementsMonthlyAllocationDataAfterSaved',
      setCustomizedMonthlyAllocationAfterSuccessfullySaved:
        'campGoalPlanner/setCustomizedMonthlyAllocationAfterSuccessfullySaved',
      sendChurchMinistryCustomizeMonthlyAllocationData:
        'campGoalPlanner/sendChurchMinistryCustomizeMonthlyAllocationData',
      sendGideonCardCustomizeMonthlyAllocationData: 'campGoalPlanner/sendGideonCardCustomizeMonthlyAllocationData',
      sendAuxiliaryScriptureMonthlyAllocationData: 'campGoalPlanner/sendAuxiliaryScriptureMonthlyAllocationData',
      sendPlacementsMonthlyAllocationData: 'campGoalPlanner/sendPlacementsMonthlyAllocationData',
      sendPWTsMonthlyAllocationData: 'campGoalPlanner/sendPWTsMonthlyAllocationData',
      sendFaithFundAndBarnabasFundMonthlyAllocationData:
        'campGoalPlanner/sendFaithFundAndBarnabasFundMonthlyAllocationData'
    }),
    closeModal() {
      this.$emit('close-modal')
    },
    column1MonthlyAllocationTransferGoalHandler(monthIndex, lastFYGoalValue) {
      this.monthlyAllocationData[monthIndex].column1PlanningGoal = lastFYGoalValue
      this.column1InputValueStartChanging = true
    },
    column2MonthlyAllocationTransferGoalHandler(monthIndex, lastFYGoalValue) {
      this.monthlyAllocationData[monthIndex].column2PlanningGoal = lastFYGoalValue
      this.column2InputValueStartChanging = true
    },
    column2InputChangeHandler() {
      this.column2InputValueStartChanging = true
    },
    column1InputChangeHandler() {
      this.column1InputValueStartChanging = true
    },
    async sendCustomizeMADataWithRespectToRouteGoalName() {
      const currentRouteName = this.$route.params.campGoalName
      const column2PlanningGoals = []
      const column1PlanningGoals = []
      let response

      this.monthlyAllocationData.forEach(itm => {
        column2PlanningGoals.push(parseInt(itm.column2PlanningGoal))
        column1PlanningGoals.push(parseInt(itm.column1PlanningGoal))
      })

      const campKey = this.officerToolbarSelected ? this.officerToolbarSelected.camp : this.userCampKey

      const correspondingAPICall = new Map([
        [
          PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation,
          async () => {
            const status = await this.sendChurchMinistryCustomizeMonthlyAllocationData({
              churchMinistryMAData: {
                CampOrgKey: campKey,
                ChurchMinistryGoals: column2PlanningGoals,
                ChurchPresentationGoals: column1PlanningGoals
              }
            })
            response = status
          }
        ],
        [
          PermittedGoalRouteNames.GideonCard,
          async () => {
            const status = await this.sendGideonCardCustomizeMonthlyAllocationData({
              gideonCardMAData: {
                CampOrgKey: campKey,
                MonthlyGoals: column2PlanningGoals
              }
            })
            response = status
          }
        ],
        [
          PermittedGoalRouteNames.AuxiliaryScripture,
          async () => {
            const status = await this.sendAuxiliaryScriptureMonthlyAllocationData({
              auxiliaryScriptureMAData: {
                CampOrgKey: campKey,
                MonthlyGoals: column2PlanningGoals
              }
            })
            response = status
          }
        ],
        [
          PermittedGoalRouteNames.Placements,
          async () => {
            const status = await this.sendPlacementsMonthlyAllocationData({
              placementsMAData: {
                CampOrgKey: campKey,
                MonthlyGoals: column2PlanningGoals,
                ShortCode: this.column2GoalShortCode
              }
            })
            response = status
          }
        ],
        [
          PermittedGoalRouteNames.PersonalWorkersTestaments,
          async () => {
            const status = await this.sendPWTsMonthlyAllocationData({
              personalWorkersTestamentsMAData: {
                CampOrgKey: campKey,
                MonthlyGoals: column2PlanningGoals,
                ShortCode: this.column2GoalShortCode
              }
            })
            response = status
          }
        ],
        [
          PermittedGoalRouteNames.FaithFund,
          async () => {
            const status = await this.sendFaithFundAndBarnabasFundMonthlyAllocationData({
              faithFundAndBarnabasFundMAData: {
                CampOrgKey: campKey,
                BarnabasFundGoals: column2PlanningGoals,
                FaithFundGoals: column1PlanningGoals
              }
            })
            response = status
          }
        ]
      ]).get(currentRouteName)
      await correspondingAPICall()
      return response
    },
    setFormForPlacementsOrPWTsWithNoData() {
      this.isModalLoading = true
      let monthlyAllocationArray = []
      let actuals = this.spreadGoalAcross12Months(this.monthlyAllocationTotalData.column2FY1GoalTotal)
      this.monthsInOrder.forEach((month, index) => {
        monthlyAllocationArray.push({
          period: month,
          column2PlanningGoal: 0,
          column2LastFYActual: actuals[index]
        })
      })
      this.isModalLoading = false
      this.monthlyAllocationData = monthlyAllocationArray
    },
    setFormForFirstTimeCustomizedMA() {
      this.isModalLoading = true
      let monthlyAllocationArray = []
      let column1Goals = this.spreadGoalAcross12Months(this.monthlyAllocationTotalData.column1Total)
      let column2Goals = this.spreadGoalAcross12Months(this.monthlyAllocationTotalData.column2Total)
      let column2FYActuals = this.spreadGoalAcross12Months(this.monthlyAllocationTotalData.column2FY1GoalTotal)
      this.monthsInOrder.forEach((month, index) => {
        if (this.isFaithFundPage) {
          const column1FYActuals = this.spreadGoalAcross12Months(this.monthlyAllocationTotalData.column1FY1GoalTotal)
          monthlyAllocationArray.push({
            period: month,
            column1PlanningGoal: column1Goals[index],
            column1LastFYActual: column1FYActuals[index],
            column2PlanningGoal: column2Goals[index],
            column2LastFYActual: column2FYActuals[index]
          })
        } else {
          monthlyAllocationArray.push({
            period: month,
            column1PlanningGoal: column1Goals[index],
            column2PlanningGoal: column2Goals[index],
            column2LastFYActual: column2FYActuals[index]
          })
        }
      })

      this.isModalLoading = false
      this.monthlyAllocationData = monthlyAllocationArray
    },
    spreadGoalAcross12Months(amount) {
      let result = new Array(12)
      let baseAmount = amount / 12
      let overage = amount % 12
      for (let i = 0; i < 12; i++) {
        result[i] = Math.trunc(baseAmount)
        if (overage > 0) {
          result[i]++
          overage--
        }
      }

      return result
    },
    async setFormForAlreadySelectedCustomizedMA() {
      this.isModalLoading = true
      this.monthlyAllocationData = window.structuredClone(this.getCustomizeMonthlyAllocationData.customizedMAData)
      this.isModalLoading = false
    },
    async modalSaveHandler() {
      if (this.isGoalReadOnlyMode) {
        this.$emit('close-modal')
        return
      }
      if (this.hasColumn2InputsTotalBoundError || this.hasColumn1InputsTotalBoundError) {
        return
      }

      if (
        (!this.hasColumn2PlanningGoalsFormError &&
          !this.hasColumn1PlanningGoalsFormError &&
          !this.$v.monthlyAllocationData.$invalid &&
          this.isRouteChurchMinistryAndPresentation) ||
        (!this.hasColumn2PlanningGoalsFormError &&
          !this.hasColumn2InputsValidationError &&
          !this.isRouteChurchMinistryAndPresentation)
      ) {
        const response = await this.sendCustomizeMADataWithRespectToRouteGoalName()
        if (response === 'Success') {
          const toSaveMAData = {
            customizedMAData: this.monthlyAllocationData,
            column1PlanningGoalsTotal: this.calculatedColumn1PlanningGoalsTotal,
            column2PlanningGoalsTotal: this.calculatedColumn2PlanningGoalsTotal,
            column1LastFYActualTotal: this.monthlyAllocationTotalData.column1FY1GoalTotal,
            column2LastFYActualTotal: this.monthlyAllocationTotalData.column2FY1GoalTotal
          }
          // checking if the modal is for placements or PWTs
          if (this.$route.params.campGoalName === PermittedGoalRouteNames.Placements) {
            this.setPlacementsMonthlyAllocationDataAfterSaved({
              shortCode: this.column2GoalShortCode,
              toSaveCustomizedMAData: { campKey: this.userCampKey, data: toSaveMAData }
            })
          } else if (this.$route.params.campGoalName === PermittedGoalRouteNames.PersonalWorkersTestaments) {
            this.setPWTsMonthlyAllocationDataAfterSaved({
              shortCode: this.column2GoalShortCode,
              toSaveCustomizedMAData: { campKey: this.userCampKey, data: toSaveMAData }
            })
          } else {
            this.setCustomizedMonthlyAllocationAfterSuccessfullySaved(toSaveMAData)
          }

          this.$emit('close-modal', 'saved', {
            column1PlanningGoalsTotal: this.calculatedColumn1PlanningGoalsTotal,
            column2PlanningGoalsTotal: this.calculatedColumn2PlanningGoalsTotal
          })
          if (this.calculatedColumn1PlanningGoalsTotal !== null && this.column1InputValueStartChanging) {
            this.$emit('column1-total-changed', this.calculatedColumn1PlanningGoalsTotal)
          }
          if (this.calculatedColumn2PlanningGoalsTotal !== null && this.column2InputValueStartChanging) {
            // adding shortCode for placements and PWTs Modals
            this.$emit('column2-total-changed', this.calculatedColumn2PlanningGoalsTotal, this.column2GoalShortCode)
          }
        }
      }
    },
    getTableColumnHeader() {
      const goalName = this.$route.params.campGoalName
      let yearRange = YearRange(this.selectedFiscalYear)
      const respectiveFields =
        goalName === PermittedGoalRouteNames.ChurchMinistryAndChurchPresentation
          ? [
              {
                key: 'period',
                label: 'Monthly Period'
              },
              {
                key: 'column1PlanningGoal',
                label: 'Monthly Presentation'
              },
              {
                key: 'column2PlanningGoal',
                label: 'Monthly Amount'
              },
              {
                key: 'column2LastFYActual',
                label: yearRange + ' Goal'
              }
            ]
          : goalName === PermittedGoalRouteNames.FaithFund
          ? [
              {
                key: 'period',
                label: 'Monthly Period',
                thClass: 'thead-sm-wd'
              },
              {
                key: 'column1PlanningGoal',
                label: 'Faith Fund'
              },
              {
                key: 'column1LastFYActual',
                label: yearRange + ' Goal',
                thClass: 'text-right'
              },
              {
                key: 'column2PlanningGoal',
                label: 'Barnabas Fund'
              },

              {
                key: 'column2LastFYActual',
                label: yearRange + ' Goal',
                thClass: 'text-right'
              }
            ]
          : [
              {
                key: 'period',
                label: 'Monthly Period'
              },
              {
                key: 'column2PlanningGoal',
                label: 'Monthly Amount'
              },
              {
                key: 'column2LastFYActual',
                label: yearRange + ' Goal'
              }
            ]
      this.fields = respectiveFields
    },
    formatNumber(value, isGoalDollarValue) {
      return isGoalDollarValue ? CampGoalService.prependDollarSign(FormatNumber(value)) : FormatNumber(value)
    }
  },
  async created() {
    this.column2InputValueStartChanging = false
    this.column1InputValueStartChanging = false
    this.getTableColumnHeader()
    if (this.$route.params.campGoalName === PermittedGoalRouteNames.Placements) {
      this.isModalLoading = true
      await this.setCustomizeMonthlyAllocationDataForPlacements({
        campKey: this.userCampKey,
        langKey: this.userLanguageKey,
        shortCode: this.column2GoalShortCode
      })
      this.isModalLoading = false
      if (Object.keys(this.getCustomizeMonthlyAllocationData).length === 0) {
        this.setFormForPlacementsOrPWTsWithNoData()
        return
      }
    } else if (this.$route.params.campGoalName === PermittedGoalRouteNames.PersonalWorkersTestaments) {
      this.isModalLoading = true
      await this.setCustomizeMonthlyAllocationDataForPWTs({
        campKey: this.userCampKey,
        langKey: this.userLanguageKey,
        shortCode: this.column2GoalShortCode
      })
      this.isModalLoading = false
      if (Object.keys(this.getCustomizeMonthlyAllocationData).length === 0) {
        this.setFormForPlacementsOrPWTsWithNoData()
        return
      }
    }
    if (this.firstTimeCustomizedMA) {
      this.setFormForFirstTimeCustomizedMA()
    } else {
      await this.setFormForAlreadySelectedCustomizedMA()
    }
  }
}
</script>
<style lang="scss">
.opaque {
  .input-group-prepend {
    opacity: 0;
  }
}

.input-group {
  margin: 0 15px;

  border-radius: 0;

  .input-group-text {
    background: #ececec;
    color: #003946;
    border-radius: 0;
  }
}

.locked-label-customize {
  margin-right: 14px;
  padding: 10px;
  text-align: right;
  background: #efefef;
  color: #777777;
  width: 212px;
  cursor: not-allowed;
}

.error {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  margin-left: 50px;
  margin-top: 5px;
}

.error-md {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin-left: 25%;
}

.error-md-center {
  display: block;
  color: #df1515;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 5px;
  margin-left: 0px;
  max-width: 220px;
}

.error-center {
  display: block;
  color: #df1515;
  font-weight: 600;
  text-align: center;
}

.error-bordered {
  border-color: #f79483;
}

.error-animate {
  animation-name: shakeError;
  animation-fill-mode: forwards;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

@keyframes shakeError {
  0% {
    transform: translateX(0);
  }

  15% {
    transform: translateX(0.375rem);
  }

  30% {
    transform: translateX(-0.375rem);
  }

  45% {
    transform: translateX(0.375rem);
  }

  60% {
    transform: translateX(-0.375rem);
  }

  75% {
    transform: translateX(0.375rem);
  }

  90% {
    transform: translateX(-0.375rem);
  }

  100% {
    transform: translateX(0);
  }
}

.text-center {
  text-align: start;
}

.modal-dialog {
  .g-panel {
    margin-bottom: 30px;
  }

  .g-panel__rgt {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .btn {
      &:first-child {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }

  .arrow-btn {
    min-height: 27px;
    min-width: 35px;
    background: #01a9cf;
    border: none;
    border-radius: 3px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      max-width: 17px;
    }

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .btn-primary {
    border: 2px solid #003946;

    &:hover {
      border-color: #fbc301;
    }
  }

  .btn-cancel {
    border: 2px solid #003946;
    background: #fff;

    &:hover {
      border-color: #003946;
      background: #003946;
      color: #fff;
    }
  }

  .modal-footer {
    background: #e0e8ef;
    border-top: none;
    padding-right: 25px;

    .btn {
      margin-left: 15px;
      text-transform: capitalize;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .custom-table {
    .input-group {
      margin: 0;
      flex-wrap: nowrap;
    }
  }

  .opaque {
    .input-group-prepend {
      display: none;
    }
  }
}

.modal-header {
  border-color: #e3e3e3;
}

.modal-title {
  color: #000;
  font-family: 'DIN 1451 W01 Engschrift', sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  line-height: 32px;
}

.faith-fund-total-row {
  td {
    .faith-fund-total-row__sm-wd {
      display: block;
      text-align: left;
      padding-left: 45px;
    }

    .faith-fund-total-row__wd {
      display: block;
      text-align: right;
      max-width: 235px;
      padding-right: 11px;
    }
  }
}

.foot-three-col {
  td {
    &:first-child {
      text-align: left;
    }

    &:nth-child(2) {
      padding-right: 27px;
    }

    &:last-child {
      padding-right: 27px;
    }
  }

  .foot-three-col__width {
    max-width: 235px;
    display: block;
    text-align: right;
    padding-right: 10px;

    &.foot-three-col__width--non-dollar {
      max-width: 200px;
    }
  }

  .foot-three-col__sm-width {
    text-align: left;
    display: block;
    padding-left: 45px;
  }
}

.foot-four-col {
  td {
    &:first-child {
      text-align: left;
    }

    &:nth-child(2) {
      padding-right: 27px;
    }

    &:last-child {
      padding-right: 27px;
    }
  }

  .foot-four-col__width {
    max-width: 235px;
    display: block;
    text-align: right;
    padding-right: 10px;
  }

  .foot-four-col__md-width {
    max-width: 200px;
    display: block;
    text-align: right;
    padding-right: 10px;
  }

  .foot-four-col__sm-width {
    max-width: 63px;
    text-align: right;
    display: block;
  }
}

.custom-table {
  th {
    &.thead-sm-wd {
      width: 260px;
    }
  }
}

@media (min-width: 300px) and (max-width: 1200px) {
  .modal-xl {
    max-width: 95%;
  }
}
</style>
