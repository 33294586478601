export const GoalHelpInfo = {
  GC: 'The GideonCard receipts include donations for GideonCards and Birthday for Jesus. The goal can be calculated by multiplying the typical number of donors by the average donation.',
  CM:
    'The Church Ministry Receipt Goal can be calculated by multiplying the number of presentations by the average camp church offering.',
  FF: `Camp goals should reflect a shift of approximately 40% of giving to the Barnabas Fund with the remaining 60% still given to the Faith Fund. This shift in giving can successfully meet both Scripture and non-Scripture deficits in International Countries.`,
  BF: ``,
  AS: `The Auxiliary Scripture Fund receipts goal is the total of estimated gifts designated by camp members to meet needs for medical convention Testaments and related expenses in addition to Scriptures for International Countries and the Camp Scripture Fund.`,
  labels: {
    GV: '(video presentation goal)',
    TC: '(Form 825) (12 per Gideon)'
  }
}
