/*eslint-disable*/
import axiosErrorHandler from '../axiosErrorHandler.js'
import { GET_GOAL_API_ENDPOINT } from '@/config/goals.api.config.js'

export default class DashboardService {
  static async getCampDashboardData(campKey, langKey,stateOrgKey) {
    const dashboardEndpoint = GET_GOAL_API_ENDPOINT('CampDashboard')(campKey, langKey,stateOrgKey)
    const response = await axiosErrorHandler({
      endpoint: dashboardEndpoint,
      apiType: 'get'
    })
    if (response) return response
  }
  static async downloadCampGoalsReport(campKey, fiscalYear, campName = '') {
    const response = await axiosErrorHandler({
      endpoint: GET_GOAL_API_ENDPOINT('CampLockGoalsReport')(campKey, fiscalYear),
      apiType: 'get'
    })
    if (response) {
      const blob = new Blob([response], { type: 'application/csv' })
      const blobUrl = URL.createObjectURL(blob)
      // create temporary 'a' element
      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.download = `CampLockedReport-${campName}(${fiscalYear - 1}-${
        fiscalYear % 100 < 10 ? '0' + (fiscalYear % 100) : fiscalYear % 100
      }).csv`

      downloadLink.click()
      // Clean up the URL object after download
      URL.revokeObjectURL(blobUrl)
      downloadLink.remove()
    }
  }

  static async lockOrUnlockCampGoals({ campKey, fiscalYear, isUserWantsToLockGoals }) {
    const campGoalsLockUnlockEndpoint = GET_GOAL_API_ENDPOINT('CampGoalsLockOrUnlockUpdate')()
    const response = await axiosErrorHandler({
      endpoint: campGoalsLockUnlockEndpoint,
      apiType: 'post',
      payload: {
        CampOrgKey: campKey,
        FiscalYear: fiscalYear,
        LockGoals: isUserWantsToLockGoals
      },
      successMessage: `You have successfully ${isUserWantsToLockGoals ? 'locked' : 'unlocked'} the camp goals.`
    })
    return response
  }
}
